<template>
    <div class="rq-task-totals-container">
        <table class="table table-sm table-borderless rq-task-totals">
            <thead>
                <tr>
                    <td></td>
                    <th scope="col">Me</th>
                    <th scope="col">Dept</th>
                    <th scope="col">Other</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.key" :class="{ 'overdue-row': item.statusId === 5 }">
                    <th scope="row">{{item.statusLabel}}</th>
                    <td :class="getCellClassAttr(item, 'me')" @click="onFilterClick(item.statusId, item.me.id)">{{item.me.value}}</td>
                    <td :class="getCellClassAttr(item, 'dept')" @click="onFilterClick(item.statusId, item.dept.id)">{{item.dept.value}}</td>
                    <td :class="getCellClassAttr(item, 'other')" @click="onFilterClick(item.statusId, item.other.id)">{{item.other.value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { AssignmentFilterModel } from '../models';
    export default {
        name: "AssignmentTotalsGrid",
        props: {
            modelValue: { type: Object, default: new AssignmentFilterModel() },
            canFilter: { type: Boolean, default: true }
        },
        data: function() {
            return {
                items: [],
                filters: new AssignmentFilterModel()
            }
        },
        watch: {
            modelValue: {
                handler(newValue) {
                    this.filters = new AssignmentFilterModel(newValue);
                },
                deep: true,
                immediate: true
            }
        },
        created() {
            this.fetchData();
        },
        methods: {

            onFilterClick(statusId, assignmentId) {
                if(!this.canFilter) return;
                let isActive = this.filterIsActive(statusId, assignmentId);
                this.filters.status = isActive ? 0 : statusId;
                this.filters.assignee = isActive ? 0 : assignmentId;
                let filters = _.clone(this.filters);
                this.$emit("update:modelValue", filters);
                this.$emit("change", { filters });
            },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.OrderWorkflowApi.getDashboardTotals();
                return this.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.mapTotals(result);
                    })
                    .catch(errorInfo => {
                        this.$toast.error("Failed to get Dashboard Totals");
                    });
            },

            filterIsActive(statusId, assignmentId) {
                return this.filters.status === statusId
                    && this.filters.assignee === assignmentId;
            },

            getCellClassAttr(item, valueAttr) {
                let statusId = _.parseNumber(item.statusId, 0);
                let valueId = _.parseNumber(item[valueAttr].id, 0);
                let value = _.parseNumber(item[valueAttr].value, 0);
                return {
                    "no-tasks": value === 0,
                    "active": this.filterIsActive(statusId, valueId)
                };
            },

            mapTotals(totalItems) {
                let totalFields = {
                    /*greaterThan30:"", */
                    open: { id: 0, label: "All Open" },
                    today: { id: 1, label: "Due Today" },
                    within7: { id: 2, label: "Due in 7 Days" },
                    within30: { id: 3, label: "Due in 30 Days" },
                    overdue: { id: 5, label: "Overdue" }
                };
                let groupedTotals = {
                    me: _.find(totalItems, { assignmentId: 1 }),
                    dept: _.find(totalItems, { assignmentId: 2 }),
                    other:  _.find(totalItems, { assignmentId: 3 })
                };
                this.items = _.map(totalFields, (v,k) => {
                    return {
                        key: _.uniqueId(),
                        statusId: v.id,
                        statusLabel: v.label,
                        me: { id: groupedTotals.me.assignmentId, value: groupedTotals.me[k] },
                        dept: { id: groupedTotals.dept.assignmentId, value: groupedTotals.dept[k] },
                        other: { id: groupedTotals.other.assignmentId, value: groupedTotals.other[k] }
                    };
                });
            },

            reload() {
                this.fetchData();
            }
        }
    }
</script>