f<template>
    <div class="rq-container content-wrapper">
        <div class="step-title">Step {{stepNumber}}</div>
        <rq-page-section
            title="Title Production and Policy Data for Order Header"
            header-size="lg"
            borderless>
        </rq-page-section>
        <div class="wizard-table-section">
            <div class="rq-table-container rq-table-container-bordered">
                <rq-scroll-container
                    ref="psElement"
                    ps-class-attr="rq-table-scroll-container"
                    @updated="onScrollUpdated"
                    perfect-scrollbar
                    update-on-resize>
                    <table ref="tableElement" class="rq-table">
                        <thead>
                            <tr>
                                <td>Field Name</td>
                                <td>Current Data</td>
                                <td>Pending Data</td>
                                <td>Override?</td>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr>
                                <td>Commitment Number</td>
                                <td>{{titleProductionMapping.finalCommitmentNumber}}</td>
                                <td>{{titleProductionMapping.stageCommitmentNumber}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_commitment_number"
                                        v-model="titleProductionMapping.acceptStageCommitmentNumber"
                                    />
                                </td>
                            </tr> -->
                            <tr>
                                <td>Effective Date</td>
                                <td>{{titleProductionMapping.finalEffectiveDate}}</td>
                                <td>{{titleProductionMapping.displayStageEffectiveDate}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_effective_date"
                                        v-model="titleProductionMapping.acceptStageEffectiveDate"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Issue Date</td>
                                <td>{{titleProductionMapping.finalIssueDate}}</td>
                                <td>{{titleProductionMapping.stageIssueDate}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_issue_date"
                                        v-model="titleProductionMapping.acceptStageIssueDate"
                                    />
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>Binder Date</td>
                                <td>{{titleProductionMapping.finalBinderDate}}</td>
                                <td>{{titleProductionMapping.stageBinderDate}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_binder_date"
                                        v-model="titleProductionMapping.acceptStageBinderDate"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Binder Number</td>
                                <td>{{titleProductionMapping.finalBinderNumber}}</td>
                                <td>{{titleProductionMapping.stageBinderNumber}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_binder_number"
                                        v-model="titleProductionMapping.acceptStageBinderNumber"
                                    />
                                </td>
                            </tr> -->
                            <tr>
                                <td>Owner Insured</td>
                                <td v-if="showOwnerInsuredDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalOwnerInsured, titleProductionMapping.stageOwnerInsuredText, 'Owner Insured')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.finalOwnerInsured }}
                                </td>
                                <td v-if="showOwnerInsuredDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalOwnerInsured, titleProductionMapping.stageOwnerInsuredText, 'Owner Insured')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.stageOwnerInsuredText }}
                                </td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_insured"
                                        v-model="titleProductionMapping.acceptStageOwnerInsured"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owner Insured Override</td>
                                <td>{{titleProductionMapping.finalOwnerInsuredOverride}}</td>
                                <td>{{titleProductionMapping.stageOwnerInsuredOverride}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_insured_override"
                                        v-model="titleProductionMapping.acceptStageOwnerInsuredOverride"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Current Holders</td>
                                <td v-if="showCurrentHolderDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalCurrentHolders, titleProductionMapping.stageCurrentHoldersText, 'Current Holders')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.finalCurrentHolders }}
                                </td>
                                <td v-if="showCurrentHolderDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalCurrentHolders, titleProductionMapping.stageCurrentHoldersText, 'Current Holders')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.stageCurrentHoldersText }}
                                </td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_current_holders"
                                        v-model="titleProductionMapping.acceptStageCurrentHolders"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Current Holders Override</td>
                                <td>{{titleProductionMapping.finalCurrentHoldersOverride}}</td>
                                <td>{{titleProductionMapping.stageCurrentHoldersOverride}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_current_holders_override"
                                        v-model="titleProductionMapping.acceptStageCurrentHoldersOverride"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Interest In Land</td>
                                <td v-if="showInterestInLandDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalInterestInLand, titleProductionMapping.stageInterestInLandText, 'Interest In Land')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.finalInterestInLand }}
                                </td>
                                <td v-if="showInterestInLandDiff">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-link btn-theme btn-action-link"
                                        @click="openContentDiff(titleProductionMapping.finalInterestInLand, titleProductionMapping.stageInterestInLandText, 'Interest In Land')">View
                                    </button>
                                </td>
                                <td v-else>
                                    {{ titleProductionMapping.stageInterestInLandText }}
                                </td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_interest_in_land"
                                        v-model="titleProductionMapping.acceptStageInterestInLand"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Interest In Land Override</td>
                                <td>{{titleProductionMapping.finalInterestInLandOverride}}</td>
                                <td>{{titleProductionMapping.stageInterestInLandOverride}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_interest_in_land_override"
                                        v-model="titleProductionMapping.acceptStageInterestInLandOverride"
                                    />
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>County ID</td>
                                <td>{{titleProductionMapping.finalCountyID}}</td>
                                <td>{{titleProductionMapping.stageCountyID}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_county_id"
                                        v-model="titleProductionMapping.acceptStageCountyID"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Property Type ID</td>
                                <td>{{titleProductionMapping.finalPropertyTypeID}}</td>
                                <td>{{titleProductionMapping.stagePropertyTypeID}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_property_type_id"
                                        v-model="titleProductionMapping.acceptStagePropertyTypeID"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Signatory</td>
                                <td>{{titleProductionMapping.finalSignatory}}</td>
                                <td>{{titleProductionMapping.stageSignatory}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_signatory"
                                        v-model="titleProductionMapping.acceptStageSignatory"
                                    />
                                </td>
                            </tr> -->
                            <tr>
                                <td>Standby Year</td>
                                <td>{{titleProductionMapping.finalStandbyYear}}</td>
                                <td>{{titleProductionMapping.stageStandbyYear}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_standby_year"
                                        v-model="titleProductionMapping.acceptStageStandbyYear"
                                    />
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>Exceptions</td>
                                <td>{{titleProductionMapping.finalExceptions}}</td>
                                <td>{{titleProductionMapping.stageExceptions}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_exceptions"
                                        v-model="titleProductionMapping.acceptStageExceptions"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owner Policy Number</td>
                                <td>{{titleProductionMapping.finalOwnerPolicyNumber}}</td>
                                <td>{{titleProductionMapping.stageOwnerPolicyNumber}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_policy_number"
                                        v-model="titleProductionMapping.acceptStageOwnerPolicyNumber"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owner Policy Number Pool ID</td>
                                <td>{{titleProductionMapping.finalOwnerPolicyNumberPoolID}}</td>
                                <td>{{titleProductionMapping.stageOwnerPolicyNumberPoolID}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_policy_number_pool_id"
                                        v-model="titleProductionMapping.acceptStageOwnerPolicyNumberPoolID"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owener Policy Date</td>
                                <td>{{titleProductionMapping.finalOwnerPolicyDate}}</td>
                                <td>{{titleProductionMapping.stageOwnerPolicyDate}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_policy_date"
                                        v-model="titleProductionMapping.acceptStageOwnerPolicyDate"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owner Remit Date</td>
                                <td>{{titleProductionMapping.finalOwnerRemitDate}}</td>
                                <td>{{titleProductionMapping.stageOwnerRemitDate}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_remit_date"
                                        v-model="titleProductionMapping.acceptStageOwnerRemitDate"
                                    />
                                </td>
                            </tr> -->
                            <tr>
                                <td>Owner Remit Date Lock</td>
                                <td>{{titleProductionMapping.finalOwnerRemitDateLock}}</td>
                                <td>{{titleProductionMapping.stageOwnerRemitDateLock}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_remit_date_lock"
                                        v-model="titleProductionMapping.acceptStageOwnerRemitDateLock"
                                    />
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>Owner Schedule B</td>
                                <td>{{titleProductionMapping.finalOwnerScheduleB}}</td>
                                <td>{{titleProductionMapping.stageOwnerScheduleB}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_schedule_b"
                                        v-model="titleProductionMapping.acceptStageOwnerScheduleB"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Owner Schedule B Override</td>
                                <td>{{titleProductionMapping.finalOwnerScheduleBOverride}}</td>
                                <td>{{titleProductionMapping.stageOwnerScheduleBOverride}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_schedule_b_override"
                                        v-model="titleProductionMapping.acceptStageOwnerScheduleBOverride"
                                    />
                                </td>
                            </tr> -->
                            <tr>
                                <td>Owner Strike Exception 2</td>
                                <td>{{titleProductionMapping.finalOwnerStrikeException2}}</td>
                                <td>{{titleProductionMapping.stageOwnerStrikeException2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_owner_strike_exception_2"
                                        v-model="titleProductionMapping.acceptStageOwnerStrikeException2"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO1</td>
                                <td>{{titleProductionMapping.finalStrikeO1}}</td>
                                <td>{{titleProductionMapping.stageStrikeO1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_1"
                                        v-model="titleProductionMapping.acceptStageStrikeO1"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO2</td>
                                <td>{{titleProductionMapping.finalStrikeO2}}</td>
                                <td>{{titleProductionMapping.stageStrikeO2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_2"
                                        v-model="titleProductionMapping.acceptStageStrikeO2"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO3</td>
                                <td>{{titleProductionMapping.finalStrikeO3}}</td>
                                <td>{{titleProductionMapping.stageStrikeO3}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_3"
                                        v-model="titleProductionMapping.acceptStageStrikeO3"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO4</td>
                                <td>{{titleProductionMapping.finalStrikeO4}}</td>
                                <td>{{titleProductionMapping.stageStrikeO4}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_4"
                                        v-model="titleProductionMapping.acceptStageStrikeO4"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO5</td>
                                <td>{{titleProductionMapping.finalStrikeO5}}</td>
                                <td>{{titleProductionMapping.stageStrikeO5}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_5"
                                        v-model="titleProductionMapping.acceptStageStrikeO5"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO6</td>
                                <td>{{titleProductionMapping.finalStrikeO6}}</td>
                                <td>{{titleProductionMapping.stageStrikeO6}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_6"
                                        v-model="titleProductionMapping.acceptStageStrikeO6"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO7</td>
                                <td>{{titleProductionMapping.finalStrikeO7}}</td>
                                <td>{{titleProductionMapping.stageStrikeO7}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_7"
                                        v-model="titleProductionMapping.acceptStageStrikeO7"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO8</td>
                                <td>{{titleProductionMapping.finalStrikeO8}}</td>
                                <td>{{titleProductionMapping.stageStrikeO8}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_8"
                                        v-model="titleProductionMapping.acceptStageStrikeO8"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>StrikeO9</td>
                                <td>{{titleProductionMapping.finalStrikeO9}}</td>
                                <td>{{titleProductionMapping.stageStrikeO9}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_strike_o_9"
                                        v-model="titleProductionMapping.acceptStageStrikeO9"
                                    />
                                </td>
                            </tr>
                            <RqTableSpacerRow
                                :height="spacerHeight"
                                :col-count="colCount"
                            />
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4">
                                    <div class="text-right">
                                        <b-btn
                                            automation_id="btn_select_all"
                                            variant="action-link"
                                            @click="onToggleSelectAll">{{selectAllLabel}}
                                        </b-btn>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </rq-scroll-container>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { RqTableSpacerRow, useRqTableSpacer } from "@/shared/components/rq/RqTableSpacerRow";
    import {useGlobalDialogs} from "@/shared/composables"

    export default {
        name: "CommitmentPolicyHeaderMapping",
        props: {
            stepNumber: { type: Number, default: 6 },
            mapping: { type: Object, default: () => ({}) },
        },
        components: {
            RqTableSpacerRow
        },
        setup() {
            const{
                tableElement,
                spacerHeight,
                updateSpacerHeight
            } = useRqTableSpacer();

            const {
                openContentDiff
            } = useGlobalDialogs();

            return {
                tableElement,
                spacerHeight,
                updateSpacerHeight,
                openContentDiff
            };

        },
        data() {
            return {
                selectedAll: false,
                colCount: 4
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            titleProductionMapping(){
                return this.mapping || null;
            },
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All" },
            showOwnerInsuredDiff() {
                return  !_.isEmpty(this.titleProductionMapping.finalOwnerInsured)
                    || !_.isEmpty(this.titleProductionMapping.stageOwnerInsuredText);
            },
            showInterestInLandDiff() {
                return  !_.isEmpty(this.titleProductionMapping.finalInterestInLand)
                    || !_.isEmpty(this.titleProductionMapping.stageInterestInLandText);
            },
            showCurrentHolderDiff() {
                return  !_.isEmpty(this.titleProductionMapping.finalCurrentHolders)
                    || !_.isEmpty(this.titleProductionMapping.stageCurrentHoldersText);
            }
        },

        methods:{
            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },
            
            getStringLength(str) {
                return str ? str.length : 0;
            },

            setOverrides(value){
                // Dev note: commented these out here and in the UI because they appear to be unneeded currently.
                // However, all of the data from the PCStageCommitmentHeaderData table is avalailable if we need it later.
                // Just need to uncomment as needed.

                //this.titleProductionMapping.acceptStageBinderDate = value;
                //this.titleProductionMapping.acceptStageBinderNumber = value;
                //this.titleProductionMapping.acceptStageCommitmentNumber = value;
                //this.titleProductionMapping.acceptStageCountyID = value;
                //this.titleProductionMapping.acceptStageExceptions = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyDate = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyNumber = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyNumberPoolID = value;
                //this.titleProductionMapping.acceptStageOwnerRemitDate = value;
                //this.titleProductionMapping.acceptStageOwnerScheduleB = value;
                //this.titleProductionMapping.acceptStageOwnerScheduleBOverride = value;
                //this.titleProductionMapping.acceptStagePropertyTypeID = value;
                //this.titleProductionMapping.acceptStageSignatory = value;
                this.titleProductionMapping.acceptStageCurrentHolders = value;
                this.titleProductionMapping.acceptStageCurrentHoldersOverride = value;
                this.titleProductionMapping.acceptStageEffectiveDate = value;
                this.titleProductionMapping.acceptStageInterestInLand = value;
                this.titleProductionMapping.acceptStageInterestInLandOverride = value;
                this.titleProductionMapping.acceptStageIssueDate = value;
                this.titleProductionMapping.acceptStageOwnerInsured = value;
                this.titleProductionMapping.acceptStageOwnerInsuredOverride = value;
                this.titleProductionMapping.acceptStageOwnerRemitDateLock = value;
                this.titleProductionMapping.acceptStageOwnerStrikeException2 = value;
                this.titleProductionMapping.acceptStageStandbyYear = value;
                this.titleProductionMapping.acceptStageStrikeO1 = value;
                this.titleProductionMapping.acceptStageStrikeO2 = value;
                this.titleProductionMapping.acceptStageStrikeO3 = value;
                this.titleProductionMapping.acceptStageStrikeO4 = value;
                this.titleProductionMapping.acceptStageStrikeO5 = value;
                this.titleProductionMapping.acceptStageStrikeO6 = value;
                this.titleProductionMapping.acceptStageStrikeO7 = value;
                this.titleProductionMapping.acceptStageStrikeO8 = value;
                this.titleProductionMapping.acceptStageStrikeO9 = value;
            },

            onScrollUpdated(e) {
                this.updateSpacerHeight();
            }
        },
    }
</script>
