<template>
    <div class="content-wrapper workflow">
        <rq-banner
            :message="validationBannerMessage"
            variant="error"
            icon="fas fa-exclamation-circle"
            :visible="showValidationBanner"
            dismissable
        />

        <rq-page-section
            :title="pageTitle"
            header-size="lg"
            class="filter-alerts-section"
            >
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_refresh_alerts"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="refresh"
                            >Refresh
                        </b-button>
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col-xs-12 col-lg-6">
                    <span class="rq-title text-no-transform">Filter by Type, Status, and Dates</span>
                    <div class="row">
                        <div class="col col-6 form-group">
                            <label for="drp_type">Type</label>
                            <dx-select-box
                                :input-attr="{ id:'drp_type', automation_id:'drp_type' }"
                                :items="typeItems"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                v-model="selectedTypeFilter"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="col col-6 form-group">
                            <label for="drp_status">Status</label>
                            <dx-select-box
                                :input-attr="{ id:'drp_status', automation_id:'drp_status' }"
                                :items="statusItems"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                v-model="selectedStatusFilter"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="col form-group">
                            <label for="txt_event_range">Show Event From</label>
                            <rq-date-range
                                id="txt_event_range"
                                v-model:start-date="startDate"
                                v-model:end-date="endDate"
                                match-value="before-clear"
                                match-field="end"
                                show-calendar-drop-downs
                                show-clear-buttons
                                no-calendars
                                borderless
                            />
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-lg-6 col-xl-4 m-xl-auto">
                    <span class="rq-title col-title">Filter by Alert Types</span>
                    <internet-alerts-totals-grid
                        ref="totalsGrid"
                        :has-type-filter="hasTypeFilter"
                        :currentFileOnly="currentFileOnly"
                        :orderId="orderId"
                        @filter-click="applyFilter($event)"
                    />
                </div>
            </div>
        </rq-page-section>

        <rqdx-action-data-grid
            ref="actionGridComponent"
            title="Alerts"
            class="task-grid"
            automation_id="dg_alerts"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            v-model:search-value="searchTerm"
            search-mode="field"
            :check-action-permission="executionService.canExecuteAction"
            export-file-name="Alerts"
            search-input-id="txt_alerts_grid_search"
            integrated-search
            @go-to-file="onGoToFile"
            @toggle-read="onToggleRead"
            @process-file="onProcessFile"
            @rowDoubleClick="onGridRowDoubleClick"
            @clear-filters="onClearFilters"
            rq-filters
            :readOnly="readOnly"
        />

    </div>
</template>

<script>
    import InternetAlertsTotalsGrid from '../components/InternetAlertsTotalsGrid';
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SearchRequest } from '@/shared/models/models';
    import AlertDataMappingWizard from "../components/alerts/AlertDataMappingWizard";
    import {AlertDataMapper} from "../models";

    const DefaultVisibleColumns = [
        "company", "contact", "pcActionDate", "pcAction", "pcLogTypeDescription", "pcViewed", "branch", "region", "processedReadBy", "pcActionInfo", "gfno", "pcLogID"
    ];
    const FilterableColumns = [
        "company", "contact", "pcActionDate", "pcAction", "pcLogTypeDescription", "pcViewed", "branch", "region", "processedReadBy", "pcActionInfo", "gfno", "pcLogID",
        "closingAgent", "escrowOfficer", "escrowProcessor", "salesRepresentative", "titleProcessor", "titleOfficer"
    ];
    const SortableColumns = [
        "company", "contact", "pcActionDate", "pcAction", "pcLogTypeDescription", "pcViewed", "branch", "region", "processedReadBy", "pcActionInfo", "gfno", "pcLogID",
        "closingAgent", "escrowOfficer", "escrowProcessor", "salesRepresentative", "titleProcessor", "titleOfficer"
    ];

    export default {
        name: 'InternetAlarms',
        components: {
            InternetAlertsTotalsGrid
        },
        props: {
            currentFileOnly: { type: Boolean, default:false }
        },
        data: function() {
            return {
                searchTerm: "",
                showValidationBanner: false,
                validationBannerMessage: "",
                selectedTypeFilter: null,
                selectedStatusFilter: null,
                startDate: null,
                endDate: null,
                items:[],
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "executionService",
                "currentSession"
            ]),
            currentUserId() { return this.currentSession.userId; },
            orderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            pageTitle() { return this.currentFileOnly ? "Filter File Alerts" : "Filter Alerts"},
            hasTypeFilter() { return !_.isNil(this.selectedTypeFilter) || !_.isNil(this.selectedStatusFilter); },
            totalsGridInstance() { return this.$refs.totalsGrid; },
            gridInstance() { return this.$refs.actionGridComponent.getGridInstance(); },
            typeItems(){
                return [
                    {id:"I", name:"Information"},
                    {id:"N", name:"New File"},
                    {id:"U", name:"File Update"},
                ]
            },
            statusItems(){
                return [
                    {id:"U", name:"Unread"},
                    {id:"R", name:"Read"},
                ]
            },
            selectionActions() {
                return [
                    { name: "go-to-file", text: "Go To File", eventName: "go-to-file", requireSelection: true, tooltip: "Go to File", disabled: function(e) { return !(e.data.ordersID > 0); } },
                    { name: "mark-read", text: "Mark Read", eventName: "toggle-read", requireSelection: true, allowMultiSelection: true, tooltip: "Mark one or more events as read", disabled: function(e) { return !_.every(e.data, ['pcViewed', false]); } },
                    { name: "mark-unread", text: "Mark Unread", eventName: "toggle-read", requireSelection: true, allowMultiSelection: true, tooltip: "Mark one or more events as unread", disabled: function(e) { return !_.every(e.data, ['pcViewed', true]); }  },
                    { name: "process", text: "Process File", eventName: "process-file", requireSelection: true, tooltip: "Process the incoming file data", disabled: function(e) { return !((e.data.ordersID > 0) && (e.data.pcLogType !== "I")); } },
                ];
            },
        },

        created() {
            this.initGridConfig();
        },

        watch: {
            selectedTypeFilter(newVal, oldVal){
                if(newVal === oldVal) return;
                this.refresh();
            },

            selectedStatusFilter(newVal, oldVal){
                if(newVal === oldVal) return;
                this.refresh();
            },

            startDate(newVal, oldVal){
                if(newVal === oldVal) return;
                this.refresh();
            },

            endDate(newVal, oldVal){
                if(newVal === oldVal) return;
                this.refresh();
            }
        },

        methods: {
            applyFilter(e){
                const self = this;
                if(_.isNil(e)) return;

                switch(e.eventTypeID){
                    case 1:
                        self.selectedTypeFilter = "I";
                        break;
                    case 2:
                        self.selectedTypeFilter = "U";
                        break;
                    case 3:
                        self.selectedTypeFilter = "N";
                        break;
                }

                self.selectedStatusFilter = e.isRead ? "R" : "U";
            },

            onGridRowDoubleClick(e){
                this.onProcessFile(e);
            },

            onGoToFile(e){
                if (!e.data || _.lte(e.data.ordersID, 0)) return;
                this.$rq.goToFile(e.data.ordersID);
            },

            onToggleRead(e){
                const self = this;
                let ids = [];
                if(e.data.length > 0){
                    _.each(e.data, (i) => {ids.push(i.pcLogID)})
                }
                else{
                    return;
                }

                let promise = self.$api.InternetAlertsApi.toggleRead(ids);
                self.$rqBusy.wait(promise).then(()=>{
                    self.$toast.success("Record(s) updated successfully");
                    self.refresh();
                    self.gridInstance.clearSelection();
                })
                .catch(error => {
                    self.$toast.error("Toggle Read Failed.");
                    console.error("Toggle Read Failed.", error);
                });

            },

            onProcessFile(e){
                const self = this;
                if (!e.data || _.lte(e.data.pcLogID, 0)) return;
                let pcLogID = e.data.pcLogID;
                let mapping = null;

                let onOk = () => {
                    self.refresh();
                };

                let promise = self.$api.InternetAlertsApi.getAlertDataMapping(pcLogID);
                self.$rqBusy.wait(promise).then(result => {
                    mapping = new AlertDataMapper(result);
                    self.$dialog.open({
                        title: "Data Mapping Wizard",
                        height: 700,
                        width: 1380,
                        closeOnEsc: true,
                        hideHeader: true,
                        hideFooter: true,
                        scrollable: false,
                        component: AlertDataMappingWizard,
                        props: {
                            mapping: mapping,
                        },
                        onOk
                    });
                }).catch(error => {
                    console.log(error);
                    self.$toast.error({ message: error.errorMessage });
                });
            },

            onClearFilters(e){
                this.selectedTypeFilter = null;
                this.selectedStatusFilter = null;
                this.startDate = null;
                this.endDate = null;
            },

            initGridConfig(){
                const self = this;
                let columns = [
                        {
                            dataField: "company", caption: "Company", dataType: "string", visible: true
                        },
                        {
                            dataField: "contact", dataType: "string", visible: true
                        },
                        {
                            dataField: 'pcActionDate', caption: 'Date / Time', dataType: "datetime", width: 150, minWidth: 100,
                        },
                        {
                            dataField: "pcAction", caption: "Description", dataType: "string", visible: true
                        },
                        {
                            dataField: "pcLogTypeDescription", caption: "Type", dataType: "string", visible: true
                        },
                        {
                            dataField: "pcViewed", caption: "Read", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, visible: true
                        },
                        {
                            dataField: "branch", dataType: "string", visible: true
                        },
                        {
                            dataField: "region", dataType: "string", visible: true
                        },
                        {
                            dataField: "processedReadBy", caption: "Processed/Read By", dataType: "string", visible: true
                        },
                        {
                            dataField: "pcActionInfo", caption: "Info", dataType: "string", visible: true
                        },
                        {
                            dataField: "gfno", caption: "File #", dataType: "string", visible: true
                        },
                        {
                            dataField: "pcLogID", caption: "PCLogID", dataType: "number", visible: true,
                        },
                        {
                            dataField: "closingAgent", caption: "Closing Agent", dataType: "string",
                        },                        
                        {
                            dataField: "escrowOfficer", caption: "Escrow Officer", dataType: "string",
                        },
                        {
                            dataField: "escrowProcessor", caption: "Escrow Processor", dataType: "string",
                        },
                        {
                            dataField: "salesRepresentative", caption: "Sales Rep", dataType: "string",
                        },                        
                        {
                            dataField: "titleOfficer", caption: "Title Officer", dataType: "string",
                        },
                        {
                            dataField: "titleProcessor", caption: "Title Processor", dataType: "string",
                        },
                    ];

                    _.forEach(columns, c => {
                    c.visible = _.includes(DefaultVisibleColumns, c.dataField);
                    c.allowFiltering = _.includes(FilterableColumns, c.dataField);
                    c.allowSorting = _.includes(SortableColumns, c.dataField);
                });

                self.gridConfig = {
                    columns,
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    remoteOperations: { sorting: true, paging: true, filtering: true },
                    paging: { enabled: true, pageIndex: 0, pageSize: 50 },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,150], showInfo: true}
                };

                self.gridDataSource = {
                    key: "pcLogID",
                    load: self.fetchData
                };
            },

            fetchData(loadOptions){
                const self = this;
                let request = self.getCurrentSearchRequest(loadOptions);
                let promise = self.currentFileOnly ? self.$api.InternetAlertsApi.searchFileAlerts(request) : self.$api.InternetAlertsApi.searchAlerts(request);

                return self.$rqBusy.wait(promise)
                    .then(response => {
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(error => {
                        self.$toast.error(error.errorMessage);
                        console.error("Search Failed.", error);
                        return { data:[], totalCount: 0 };
                    });

            },

            refresh(){
                this.hasFilter
                this.totalsGridInstance.refresh();
                this.gridInstance.refresh();
            },

            getCurrentSearchRequest (loadOptions) {
                const self = this;
                let searchParams = {};
                if(self.currentFileOnly)
                    searchParams["OrderId"] = self.orderId;
                searchParams["PCLogType"] = self.selectedTypeFilter;
                searchParams["PCViewed"] = self.selectedStatusFilter;
                searchParams["StartDate"] = self.startDate;
                searchParams["EndDate"] = self.endDate;

                let request = new SearchRequest({
                    searchTerm: self.searchTerm,
                    parameters: searchParams,
                    sortBy: [],
                    pageNumber: 1,
                    pageSize: 50,
                    pagingEnabled: true
                });
                request.parseLoadOptions(loadOptions);
                return request;
            },
        }
    }
</script>
