<template>
    <div class="rq-task-totals-container">
        <table class="table table-sm table-borderless rq-task-totals">
            <thead>
                <tr>
                    <td></td>
                    <th scope="col">Read</th>
                    <th scope="col">Unread</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.eventTypeID">
                    <th scope="row">{{item.eventType}}</th>
                    <td :class="getCellClassAttr(item, 'readCount')" @click="onFilterClick(item.eventTypeID, true)">{{item.readCount}}</td>
                    <td :class="getCellClassAttr(item, 'unreadCount')" @click="onFilterClick(item.eventTypeID, false)">{{item.unreadCount}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { NAV_MUTATIONS } from "@/store/mutations";
    export default {
        name: "InternetAlertsTotalsGrid",
        props: {
            hasTypeFilter: { type: Boolean, required: true, default: false },
            currentFileOnly: { type: Boolean, default:false },
            orderId: { type: Number, default: 0}
        },
        data: function() {
            return {
                items: [],
                activefilter: {}
            }
        },
        watch: {

        },
        created() {
            this.fetchData();
        },
        methods: {

            onFilterClick(eventTypeID, isRead) {
                let filters = {eventTypeID: eventTypeID, isRead: isRead};
                this.activefilter = filters;
                this.$emit("filter-click", filters);
            },

            fetchData () {
                const self = this;
                let apiPromise = self.currentFileOnly ? self.$api.InternetAlertsApi.getFileAlertTotals(self.orderId) : self.$api.InternetAlertsApi.getAlertTotals();
                return this.$rqBusy.wait(apiPromise)
                    .then(results => {
                        self.items = results;
                        let totalUnread = _.sumBy(results, (r) => { return r.unreadCount; })
                        if(!self.currentFileOnly)
                            self.$store.commit(NAV_MUTATIONS.SET_UNREAD_ALERT_COUNT, totalUnread);
                    })
                    .catch(errorInfo => {
                        this.$toast.error("Failed to get Alert Totals");
                    });
            },

            getCellClassAttr(item, valueAttr) {
                let itemFilter = {eventTypeID: item.eventTypeID, isRead: _.isEqual(valueAttr, "readCount")};
                let value = _.get(item, valueAttr, 0);
                return _.isEqual(itemFilter, this.activefilter) && this.hasTypeFilter ? "active" : value > 0 ? "" : "no-tasks";
            },

            refresh() {
                this.fetchData();
            }
        }
    }
</script>