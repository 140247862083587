<template>
    <div class="rq-container">
        <div class="row">
            <div class="col-3">
                <div class="step-title">Step {{stepNumber}}</div>
                <div class="rq-title rq-title-lg p-0">Property</div>
                <div class="step-text">Property {{index+1}} of {{propertyMappings.length}}</div>
            </div>
            <div class="col-9">
                <table class="table table-sm table-borderless table-secondary">
                    <tbody>
                        <tr>
                            <th>Address 1</th>
                            <td>{{originalPropertyData.address1}}</td>
                            <th>State</th>
                            <td>{{originalPropertyData.state}}</td>
                        </tr>
                        <tr>
                            <th>Address 2</th>
                            <td>{{originalPropertyData.address2}}</td>
                            <th>Zip</th>
                            <td>{{originalPropertyData.zip}}</td>
                        </tr>
                        <tr>
                            <th>City</th>
                            <td>{{originalPropertyData.city}}</td>
                            <th>County</th>
                            <td>{{originalPropertyData.countyName}}</td>
                        </tr>                                                                                             
                    </tbody>
                </table>                      
            </div>
        </div>                   
        <div class="row">
            <div class="col-2">Create New</div>
            <div class="col">
                <rq-switch
                    automation_id="chk_create_new"
                    type="text"
                    variant="primary"
                    on="Yes"
                    off="No"
                    size="sm"
                    ref="newPropertySetting"
                    v-model="propertyMapping.isNewProperty"
                    @change="onChangeNewProperty"
                />
            </div>
        </div>
        <table class="table rq-table">
            <thead>
                <tr>
                    <td>Field Name</td>
                    <td>Current Data</td>
                    <td>Pending Data</td>
                    <td>Override?</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Legal Desc</td>
                    <td>{{propertyMapping.finalLegalDescription}}</td>
                    <td>{{propertyMapping.stageLegalDescription}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_legal_desc"
                            v-model="propertyMapping.acceptStageLegalDescription"
                            :disabled="!canAcceptStageLegalDescription"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{{propertyMapping.finalAddress1}}</td>
                    <td>{{propertyMapping.stageAddress1}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_address"
                            v-model="propertyMapping.acceptStageAddress1"
                            :disabled="!canAcceptStageAddress1"
                        />
                    </td>
                </tr>
                <tr>
                    <td>City</td>
                    <td>{{propertyMapping.finalCity}}</td>
                    <td>{{propertyMapping.stageCity}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_city"
                            v-model="propertyMapping.acceptStageCity"
                            :disabled="!canAcceptStageCity"
                        />
                    </td>
                </tr>
                <tr>
                    <td>County</td>
                    <td>{{propertyMapping.finalCountyName}}</td>
                    <td>{{propertyMapping.stageCountyName}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_county_name"
                            v-model="propertyMapping.acceptStageCountyName"
                            :disabled="!canAcceptStageCountyName"
                        />
                    </td>
                </tr>
                <tr>
                    <td>State</td>
                    <td>{{propertyMapping.finalState}}</td>
                    <td>{{propertyMapping.stageState}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_state"
                            v-model="propertyMapping.acceptStageState"
                            :disabled="!canAcceptStageState"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Zip</td>
                    <td>{{propertyMapping.finalZip}}</td>
                    <td>{{propertyMapping.stageZip}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_zip"
                            v-model="propertyMapping.acceptStageZip"
                            :disabled="!canAcceptStageZip"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Sequence</td>
                    <td>{{propertyMapping.finalSequence}}</td>
                    <td>{{propertyMapping.stageSequence}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_sequence"
                            v-model="propertyMapping.acceptStageSequence"
                            :disabled="!canAcceptStageSequence"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Acreage</td>
                    <td>{{propertyMapping.finalAcreage}}</td>
                    <td>{{propertyMapping.stageAcreage}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_acreage"
                            v-model="propertyMapping.acceptStageAcreage"
                            :disabled="!canAcceptStageAcreage"
                        />
                    </td>
                </tr>                                                                    
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">
                        <div class="text-right">
                            <b-btn
                                automation_id="btn_select_all"
                                variant="action-link"
                                v-model="selectedAll"
                                @click="onToggleSelectAll">{{selectAllLabel}}
                            </b-btn>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>           
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "PropertyMapping",
        props: {
            stepNumber: { type: Number, default: 3 },
            mapping: { type: Object, default: () => ({}) },
        },
        watch: {
            index: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.originalPropertyData = this.originalMappingData[newValue];
                },
                immediate: true
            },
        },
        data() {
            return {
                index: 0,
                originalMappingData: _.cloneDeep(this.mapping.propertyMappings),
                originalPropertyData: {},
                selectedAll: false
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All"},
            disableNewFile() { 
                return this.propertyMapping.acceptStageLegalDescription 
                    || this.propertyMapping.acceptStageAcreage 
                    || this.propertyMapping.acceptStageAddress1 
                    || this.propertyMapping.acceptStageCity 
                    || this.propertyMapping.acceptStageCountyName 
                    || this.propertyMapping.acceptStageState 
                    || this.propertyMapping.acceptStageZip 
                    || this.propertyMapping.acceptStageSequence; 
            },
            maxIndex() { return this.propertyMappings.length; },
            propertyMappings() {
                return this.mapping.propertyMappings || [];
            },
            propertyMapping() { return this.mapping.propertyMappings[this.index]; },
            canAcceptStageLegalDescription() {
                let value = _.get(this.propertyMapping, "stageLegalDescription", null);
                return !_.isNil(value);
            },
            canAcceptStageAcreage() {
                let value = _.get(this.propertyMapping, "stageAcreage", null);
                return !_.isNil(value);
            },
            canAcceptStageAddress1() {
                let value = _.get(this.propertyMapping, "stageAddress1", null);
                return !_.isNil(value);
            },
            canAcceptStageCity() {
                let value = _.get(this.propertyMapping, "stageCity", null);
                return !_.isNil(value);
            },
            canAcceptStageCountyName() {
                let value = _.get(this.propertyMapping, "stageCountyName", null);
                return !_.isNil(value);
            },
            canAcceptStageState() {
                let value = _.get(this.propertyMapping, "stageState", null);
                return !_.isNil(value);
            },
            canAcceptStageZip() {
                let value = _.get(this.propertyMapping, "stageZip", null);
                return !_.isNil(value);
            },
            canAcceptStageSequence() {
                let value = _.get(this.propertyMapping, "stageSequence", null);
                return !_.isNil(value);
            }
        },

        methods:{
            onChangeNewProperty() {
                if (this.propertyMapping.isNewProperty) {
                    // this.clearPropertyData();
                    this.selectedAll = true;
                    this.setOverrides(this.selectedAll);
                }
                else {
                    this.resetOriginalData();                    
                }
            },
            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },
            setOverrides(value) {
                if (this.canAcceptStageLegalDescription)
                    this.propertyMapping.acceptStageLegalDescription = value;
                if (this.canAcceptStageAcreage)
                    this.propertyMapping.acceptStageAcreage = value;
                if (this.canAcceptStageAddress1)
                    this.propertyMapping.acceptStageAddress1 = value;
                if (this.canAcceptStageCity)
                    this.propertyMapping.acceptStageCity = value;
                if (this.canAcceptStageCountyName)
                    this.propertyMapping.acceptStageCountyName = value;
                if (this.canAcceptStageState)
                    this.propertyMapping.acceptStageState = value;
                if (this.canAcceptStageZip)
                    this.propertyMapping.acceptStageZip = value;
                if (this.canAcceptStageSequence)
                    this.propertyMapping.acceptStageSequence = value;                      
            },
            clearPropertyData() {
                this.propertyMapping.legalDescription = null;
                this.propertyMapping.acreage = null;
                this.propertyMapping.address1 = null;
                this.propertyMapping.city = null;
                this.propertyMapping.countyName = null;
                this.propertyMapping.state = null;
                this.propertyMapping.zip = null;
                this.propertyMapping.sequence = null;
            },
            resetOriginalData() {
                this.propertyMapping.legalDescription = this.originalPropertyData.legalDescription;
                this.propertyMapping.acreage = this.originalPropertyData.acreage;
                this.propertyMapping.address1 = this.originalPropertyData.address1;
                this.propertyMapping.city = this.originalPropertyData.city;
                this.propertyMapping.countyName = this.originalPropertyData.countyName;
                this.propertyMapping.state = this.originalPropertyData.state;
                this.propertyMapping.zip = this.originalPropertyData.zip;
                this.propertyMapping.sequence = this.originalPropertyData.sequence;
                this.setOverrides(false);
            },
            onMoveNext(){
                this.index++;
                
                if (this.index < this.maxIndex) {
                    return false;
                }
                return true;
            },
        }
    }
</script>