<template>
    <div class="rq-container">
        <div class="step-title">Step {{stepNumber}}</div>
        <rq-page-section
            title="File Data"
            header-size="lg"
            borderless>
            <table class="table rq-table">
                <thead>
                    <tr>
                        <td>Field Name</td>
                        <td>Current Data</td>
                        <td>Pending Data</td>
                        <td>Override?</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Order Status</td>
                        <td>{{orderMapping.finalStatus}}</td>
                        <td>{{orderMapping.stageStatusDescription}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_status"
                                v-model="orderMapping.acceptStageStatus"
                                :disabled="!canAcceptStageStatus"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Partner File Number</td>
                        <td>{{orderMapping.finalOtherCompaniesFileNumber}}</td>
                        <td>{{orderMapping.stageOtherCompaniesFileNumber}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_partner_file_number"
                                v-model="orderMapping.acceptStageOtherCompaniesFileNumber"
                                :disabled="!canAcceptStageOtherCompaniesFileNumber"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Open Date</td>
                        <td>{{orderMapping.finalOpenDate}}</td>
                        <td>{{orderMapping.displayStageOpenDate}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_open_date"
                                v-model="orderMapping.acceptStageOpenDate"
                                :disabled="!canAcceptStageOpenDate"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Close Date</td>
                        <td>{{orderMapping.finalCloseDate}}</td>
                        <td>{{orderMapping.displayStageCloseDate}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_close_date"
                                v-model="orderMapping.acceptStageCloseDate"
                                :disabled="!canAcceptStageCloseDate"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Amount Down</td>
                        <td>{{orderMapping.finalAmountDown}}</td>
                        <td>{{orderMapping.displayStageAmountDown}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_amount_down"
                                v-model="orderMapping.acceptStageAmountDown"
                                :disabled="!canAcceptStageAmountDown"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Earnest Money</td>
                        <td>{{orderMapping.finalEarnestMoney}}</td>
                        <td>{{orderMapping.displayStageEarnestMoney}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_earnest_money"
                                v-model="orderMapping.acceptStageEarnestMoney"
                                :disabled="!canAcceptStageEarnestMoney"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Sales Price</td>
                        <td>{{orderMapping.finalSalesPrice}}</td>
                        <td>{{orderMapping.displayStageSalesPrice}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_sales_price"
                                v-model="orderMapping.acceptStageSalesPrice"
                                :disabled="!canAcceptStageSalesPrice"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Region</td>
                        <td>{{orderMapping.finalRegion}}</td>
                        <td>{{orderMapping.stageRegionDescription}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_region"
                                v-model="orderMapping.acceptStageRegionID"
                                :disabled="!canAcceptStageRegionID"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Branch</td>
                        <td>{{orderMapping.finalBranch}}</td>
                        <td>{{orderMapping.stageBranchDescription}}</td>
                        <td>
                            <b-form-checkbox
                                automation_id="chk_override_branch"
                                v-model="orderMapping.acceptStageBranchID"
                                :disabled="!canAcceptStageBranchID"
                            />
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4">
                            <div class="text-right">
                                <b-btn
                                    automation_id="btn_select_all"
                                    variant="action-link"
                                    @click="onSelectAll">{{selectAllLabel}}
                                </b-btn>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "FileMapping",
        props: {
            stepNumber: { type: Number, default: 1 },
            mapping: { type: Object, default: () => ({}) },
        },
        components: {

        },
        data() {

        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            orderMapping(){
                return this.mapping.orderMapping || null;
            },
            areAllSelected(){
                return (this.canAcceptStageStatus && this.orderMapping.acceptStageStatus || this.canAcceptStageStatus === false) &&
                (this.canAcceptStageOtherCompaniesFileNumber && this.orderMapping.acceptStageOtherCompaniesFileNumber || this.canAcceptStageOtherCompaniesFileNumber === false) &&
                (this.canAcceptStageOpenDate && this.orderMapping.acceptStageOpenDate || this.canAcceptStageOpenDate === false) &&
                (this.canAcceptStageCloseDate && this.orderMapping.acceptStageCloseDate || this.canAcceptStageCloseDate === false) &&
                (this.canAcceptStageAmountDown && this.orderMapping.acceptStageAmountDown || this.canAcceptStageAmountDown === false) &&
                (this.canAcceptStageEarnestMoney && this.orderMapping.acceptStageEarnestMoney || this.canAcceptStageEarnestMoney === false) &&
                (this.canAcceptStageSalesPrice && this.orderMapping.acceptStageSalesPrice || this.canAcceptStageSalesPrice === false) &&
                (this.canAcceptStageRegionID && this.orderMapping.acceptStageRegionID || this.canAcceptStageRegionID === false) &&
                (this.canAcceptStageBranchID && this.orderMapping.acceptStageBranchID || this.canAcceptStageBranchID === false);
            },
            selectAllLabel(){
                return this.areAllSelected ? "Unselect All" : "Select All";
            },
            canAcceptStageStatus(){
                let value = _.get(this.orderMapping, "stageStatus", null);
                return !(_.isNil(value));
            },
            canAcceptStageOtherCompaniesFileNumber(){
                let value = _.get(this.orderMapping, "stageOtherCompaniesFileNumber", null);
                if(value) return true;
                return false;
            },
            canAcceptStageOpenDate(){
                let value = _.get(this.orderMapping, "stageOpenDate", null);
                if(value) return true;
                return false;
            },
            canAcceptStageCloseDate(){
                let value = _.get(this.orderMapping, "stageCloseDate", null);
                if(value) return true;
                return false;
            },
            canAcceptStageAmountDown(){
                let value = _.get(this.orderMapping, "stageAmountDown", null);
                return !(_.isNil(value));
            },
            canAcceptStageEarnestMoney(){
                let value = _.get(this.orderMapping, "stageEarnestMoney", null);
                return !(_.isNil(value));
            },
            canAcceptStageSalesPrice(){
                let value = _.get(this.orderMapping, "stageSalesPrice", null);
                return !(_.isNil(value));
            },
            canAcceptStageRegionID(){
                let value = _.get(this.orderMapping, "stageRegionID", null);
                if(value) return true;
                return false;
            },
            canAcceptStageBranchID(){
                let value = _.get(this.orderMapping, "stageBranchID", null);
                if(value) return true;
                return false;
            },
        },

        methods:{
            onSelectAll(){
                this.setOverrides(!this.areAllSelected);
            },
            onMoveNext() {
                return true;
            },
            setOverrides(value){
                if(this.canAcceptStageStatus)
                    this.orderMapping.acceptStageStatus = value;
                if(this.canAcceptStageOtherCompaniesFileNumber)
                    this.orderMapping.acceptStageOtherCompaniesFileNumber = value;
                if(this.canAcceptStageOpenDate)                    
                    this.orderMapping.acceptStageOpenDate = value;
                if(this.canAcceptStageCloseDate)
                    this.orderMapping.acceptStageCloseDate = value;
                if(this.canAcceptStageAmountDown)                    
                    this.orderMapping.acceptStageAmountDown = value;
                if(this.canAcceptStageEarnestMoney)
                    this.orderMapping.acceptStageEarnestMoney = value;
                if(this.canAcceptStageSalesPrice)
                    this.orderMapping.acceptStageSalesPrice = value;
                if(this.canAcceptStageRegionID)                    
                    this.orderMapping.acceptStageRegionID = value;
                if(this.canAcceptStageBranchID)
                    this.orderMapping.acceptStageBranchID = value;
            },
        }
    }
</script>
