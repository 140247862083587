<template>
    <div class="h-100 d-flex text-center">
        <div class="row mt-auto mb-auto">
            <div class="col-12 h2 wizard-success mb-4">Success!</div>
            <div class="col-12 h4 text-no-transform mb-3">This data has been successfully processed.</div>
            <div class="col-12 mb-4">
                <p>If you want to go back and edit your File click Edit File.<br/>Otherwise you can close this modal.</p>
            </div>
            <div class="col-auto ms-auto">
                <b-btn
                    automation_id="btn_edit_file"
                    variant="theme"
                    @click="onEditFile">
                    <span>Edit File</span>
                    <FontAwesomeIcon icon="fas fa-square-arrow-right" class="me-2" />
                </b-btn>
            </div>
            <div class="col-auto me-auto">
                <b-btn
                    automation_id="btn_next"
                    variant="theme"
                    @click="onClose">
                    <span>Close</span>
                    <FontAwesomeIcon icon="fas fa-times-circle" class="me-2" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FinishMapping",
        props: {
            mapping: { type: Object, default: () => ({}) },
        },
        methods: {
            onEditFile(){
                this.$emit("open-file", this.mapping);
            },
            onClose(){
                this.$emit("close-wizard");
            }
        }
    }
</script>