<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="signingDashboard"
            id="dx-signing-dashboard"
            automation_id="dg_signing_dashboard"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            @navigate="navigateToOrder"
            @action="onAction"
            v-model:search-value="searchTerm"
            fixed-header
            integrated-search
            remote-search
            force-search-refresh
            :rq-filters="{ mode: 'panel', visibleColumnsOnly: true }">
            <template #filter-panel-header>
                    <div class="summary-display">
                        <div class="summary-item">
                            <div class="item-value">{{weekCount}}</div>
                            <div class="item-label">Next 7 days</div>
                        </div>
                        <div class="summary-item">
                            <div class="item-value">{{todayCount}}</div>
                            <div class="item-label">Today</div>
                        </div>
                        <div class="summary-item">
                            <div class="item-value text-danger">{{overdueCount}}</div>
                            <div class="item-label">Overdue</div>
                        </div>
                    </div>
                </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DxFilterBuilder } from "@/shared/utilities";
    import { SearchRequest } from "@/shared/models/models";

    export default {
        name:"SigningDashboard",
        props: {
            gridHeight: { default: "100%" },
            gridWidth: { default: "100%" }
        },
        data () {
            return {
                gridDataSource: [],
                selectionActions: [
                    { eventName: "navigate", text: "Go To File", requireSelection: true }
                ],
                weekCount: 0,
                todayCount: 0,
                overdueCount: 0,
                searchTerm: "",
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup"
            ]),
            baseFilter() { return []; },
        },
        created() {
            const self = this;
            let columns = [
                {
                    dataField: "description",
                    caption: "Signing Description",
                    dataType: "string",
                    minWidth: 150,
                },
                {
                    dataField: "fileNumber",
                    caption: "File #",
                    dataType: "string",
                },
                {
                    dataField: "propertyAddress",
                    caption: "Address",
                    dataType: "string",
                },
                {
                    dataField: "signingTypeID",
                    caption: "Signing Type",
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.CLOSING_TYPES)
                    },
                    calculateSortValue: "signingTypeName"
                },
                DxGridUtils.dateColumn({
                    dataField: "signingDate",
                }),
                {
                    dataField: "signingStatusID",
                    caption: "Signing Status",
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.SIGNING_STATUSES)
                    },
                    calculateSortValue: "signingStatusName"
                },
                {
                    dataField: "propertyState",
                    caption: "State",
                    dataType: "string",
                    rqFilter: {
                        displayExpr: "name",
                        valueExpr: "id",
                        dataSource: self.usStatesLookup,
                        maxDisplayedTags: 2,
                        multiTagTemplate: items => ({
                            text: `${items.length} states`,
                            title: _.join(_.map(items, "name"), ", ")
                        })
                    }
                },
                {
                    dataField: "countyId",
                    caption: "County",
                    dataType: "number",
                    calculateDisplayValue: "county",
                    alignment: "left",
                    rqFilter: {
                        displayExpr: "name",
                        valueExpr: "id",
                        dependencyDataField: "propertyState",
                        dataSource: {
                            load(opts, rawSelectedItems, filterExpr=[]) {
                                let stateFilterValue = self.parseStateFilter(filterExpr);
                                if(_.isEmpty(stateFilterValue)) return [];
                                let stateCounties = self.lookupHelpers.getCountiesInStates(stateFilterValue);
                                return _.isEmpty(opts?.searchValue)
                                    ? stateCounties
                                    : _.filter(stateCounties, c => _.includes(_.toLower(c.name), _.toLower(opts?.searchValue)));
                            }
                        },
                        inputDisabled: ({ filters }) => {
                            let stateFilterValue = self.parseStateFilter(filters);
                            return _.isEmpty(stateFilterValue)
                        },
                        filterType: "tags"
                    }
                },
                {
                    dataField: "lender",
                    dataType: "string",
                    visible: false
                },
                {
                    dataField: "propertyCity",
                    caption: "City",
                    dataType: "string",
                    visible: false
                },
                {
                    dataField: "transactionType",
                    visible: false,
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.TRANSACTION_TYPES)
                    },
                    calculateSortValue: "transactionTypeName"
                }
            ];
            self.gridConfig = {
                columns,
                columnMinWidth: 100,
                wordWrapEnabled: false,
                noDataText: "No Signings Found",
                columnAutoWidth: true,
                columnFixing: { enabled: true, columnHidingEnabled: true },
                height: self.gridHeight,
                selection: { mode: "single", requireSelection: true, allowSelectAll: false },
                remoteOperations: { paging: true, sorting: true, filtering: true }
            };
            self.gridDataSource = {
                key: "orderSigningID",
                load: self.fetchData
            };
        },
        methods: {
            fetchData(loadOptions) {
                const self = this;
                let emptyResult = { data: [], totalCount: 0 };
                let request = SearchRequest.fromLoadOptions(loadOptions);
                request.searchTerm = self.searchTerm;
                let apiPromise = _.invoke(self.$api.OrderSigningApi, "getAll", request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.weekCount = response.summary[0];
                        self.todayCount = response.summary[1];
                        self.overdueCount = response.summary[2];
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(err => {
                        console.error(err);
                        return emptyResult;
                    });
            },
            navigateToOrder(e) {
                if (!e.data || _.lte(e.data.ordersID, 0)) return;
                this.$router.push(`/order/${e.data.ordersID}/signing`);
            },
            getRequestFilter(loadOptions) {
                const self = this;
                let filterBuilder = new DxFilterBuilder(loadOptions);
                if(!_.isEmpty(self.baseFilter))
                    filterBuilder.append(self.baseFilter);
                return filterBuilder.filterExpression;
            },
            parseStateFilter(filterExpr) {
                let filterValue = [];
                let parseFilter = expr => {
                    _.forEach(expr, (item, index, arr) => {
                        if(_.isArray(item)) {
                            parseFilter(item);
                        }
                        else if(item === "propertyState") {
                            filterValue.push(arr[2]);
                        }
                    });
                }
                parseFilter(filterExpr);
                return filterValue;
            }
        }
    };
</script>
