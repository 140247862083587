import { MainLayout } from "@/layouts";
import Routes from "./routes";
import ManageCompaniesModule from '../utilities/manage-companies';

let HomeModule = {
    name: "home",
    path: "/",
    alias: "/home",
    redirect: "/recent-files",
    component: MainLayout,
    meta: { include: false },
    children: [
        ...Routes,
        ManageCompaniesModule
    ]
};

export default HomeModule;