<template>
    <RqTabs
        :tabs="tabItems"
        v-model="activeTabIndex"
        @activate-tab="onActivateTab"
        @close-tab="onCloseTab"
        @changed="onTabsChanged">
        <template
            #primary-files>
            <rqdx-action-data-grid
                ref="dataGrid"
                id="dx-primary-files"
                automation_id="dg_primary_files"
                :actions="selectionActions"
                :data-source="gridDataSource"
                :config="gridConfig"
                export-file-name="PrimaryFiles"
                @action="onGridAction"
                @row-double-click="onGridRowDoubleClick"
                integrated-search
                rq-filters>
            </rqdx-action-data-grid>
        </template>
        <template
            v-if="showSecondaryTab"
            #secondary-files>
            <rqdx-action-data-grid
                ref="detailDataGrid"
                id="dx-secondary-files"
                automation_id="dg_secondary_files"
                :actions="detailSelectionActions"
                :data-source="detailGridDataSource"
                :config="detailGridConfig"
                export-file-name="SecondaryFiles"
                @action="onGridAction"
                @row-double-click="onGridRowDoubleClick"
                integrated-search
                rq-filters>
            </rqdx-action-data-grid>
        </template>
    </RqTabs>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import NewSecondaryFileDialog from "@order-entry/dialogs/NewSecondaryFileDialog";
    import { SecondaryFileMergeDto, PrimaryOrderDto, SecondaryOrderDto } from '@order-entry/models';
    import { OrderStatus } from "@/shared/models/enums";
    import { h } from "vue";

    export default {
        name:"PortfolioManager",
        components: {  },
        props: {
        },
        data () {
            return {
                activeTabIndex: 0,
                gridDataSource: {},
                detailGridDataSource: {},
                itemKey: "ordersID",
                items: [],
                detailItems: [],
                selectedItemOrdersID: null,
                selectedPrimaryGfno: null,
                showSecondaryTab: false,
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance () { return _.get(this, "$refs.dataGrid.gridInstance", {}) || {}; },
            localSecurity(){ return this.securitySettings.findValues(["AllowSecondaryFileCreate"]); },
            detailGridInstance () { return _.get(this, "$refs.detailDataGrid.gridInstance", {}) || {}; },
            secondaryTabTitle () {return this.showSecondaryTab ? `${this.selectedPrimaryGfno} (${_.size(this.detailItems)})` : ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            tabItems() {
                return [
                    { automation_id: "btn_tab_primary_files", title: "Primary Files", name: "primary-files" },
                    { automation_id: "btn_tab_secondary_files", title: this.secondaryTabTitle, name: "secondary-files", visible: this.showSecondaryTab, showClose: true }
                ];
            }
        },
        created() {
            this.initGridConfig();
            this.fetchData();
        },
        methods: {
            fetchData() {
                const self = this;
                let apiPromise = self.$api.OrdersApi.getPrimaryOrders();

                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new PrimaryOrderDto(i));
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading Primary Orders.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            fetchSecondaryData(ordersID, gfNo) {
                const self = this;
                if (ordersID<=0) return;
                let apiPromise = self.$api.OrdersApi.getSecondaryOrders(ordersID);

                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.selectedItemOrdersID = ordersID;
                        self.selectedPrimaryGfno = gfNo;
                        self.detailItems = _.map(result, i => new SecondaryOrderDto(i));
                        self.activeTabIndex = 1;
                        self.showSecondaryTab = true;
                    })
                    .catch(error => {
                        self.activeTabIndex = 0;
                        self.selectedItemOrdersID = null;
                        self.selectedPrimaryGfno = "";
                        self.showSecondaryTab = false;
                        self.detailItems = [];
                        self.$toast.error({ message: `Error loading Secondary Orders.` });
                        return error;
                    })
                    .finally(() => {
                        self.gridInstance.clearSelection();
                        self.refreshSecondary();
                    });
            },
            initGridConfig() {
                const self = this;
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                        branchItem.regionDisplay = _.isNil(branchRegion)
                            ? "Region not found"
                            : branchRegion.description;
                        return branchItem;
                    });
                //Primary Grid
                self.selectionActions = [
                    { name: "view-secondary", text: "View Secondary Files", requireSelection: true },
                    { name: "add-secondary", text: "Add Secondary Files", requireSelection: true, disabled: function(e) { return !self.localSecurity.AllowSecondaryFileCreate ? "Access Restricted" : false; } },
                    { name: "edit", text: "Edit", requireSelection: true }
                ],

                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "gfNo",
                            caption: "File #",
                            showInColumnChooser: false,
                            minWidth: 150
                        },
                        {
                            caption: "Branch",
                            dataField: "branchID",
                            dataType: "number",
                            lookup: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource
                            },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", {class:"text-truncate"}, props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto text-truncate" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            alignment: "left",
                            calculateDisplayValue: "branch",
                            calculateSortValue: "branch"
                        },
                        { dataField: "buyer", dataType: "string" },
                        DxGridUtils.dateColumn({
                            dataField: "closeDate",
                            minWidth: 100,
                            width: 120,
                        }),
                        DxGridUtils.dateColumn({
                            dataField: "fundDate",
                            minWidth: 100,
                            width: 120,
                        }),
                        DxGridUtils.dateColumn({
                            dataField: "openDate",
                            minWidth: 100,
                            width: 120,
                        }),
                        {
                            dataField: "propertyAddress",
                            dataType: "string",
                            caption: "Property Address",
                            minWidth: 200
                        },
                        {
                            caption: "Region",
                            dataField: "regionID",
                            dataType: "number",
                            lookup: {
                                valueExpr: "regionID",
                                displayExpr: "displayName",
                                dataSource: self.regions.slice()
                            },
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                        },
                        { caption: "Secondary File Count", dataField: "secondaryCount", dataType: "number" },
                        { dataField: "seller", dataType: "string" },
                        //hidden
                        {
                            dataField: "orderCategoryID", caption: "Category",
                            dataType: "number",
                            lookup: {
                                valueExpr: "id",
                                displayExpr: "name",
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ORDER_CATEGORIES)
                            },
                            calculateSortValue: "orderCategory",
                            visible: false
                        },
                        {
                            dataField: "statusID",
                            dataType: "number",
                            caption: "Status",
                            calculateSortValue: "Status",
                            lookup: {
                                dataSource: _.filter(self.lookupHelpers.getLookupItems(self.lookupItems.STATUSES).slice(OrderStatus.New), s => s.name !== 'PaperlessCloser'),
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false
                        },
                        {
                            dataField: "isGlobal",
                            dataType: "boolean",
                            caption: "Global",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        { dataField: "closingAgent", dataType: "string", visible: false },
                        { dataField: "escrowUnit", dataType: "string", visible: false },
                        { dataField: "escrowOfficer", dataType: "string", visible: false },
                        { dataField: "escrowProcessor", dataType: "string", visible: false },
                        { dataField: "titleUnit", dataType: "string", visible: false },
                        { dataField: "titleOfficer", dataType: "string", visible: false },
                        { dataField: "titleProcessor", dataType: "string", visible: false },
                        { dataField: "salesRepresentative", dataType: "string", caption: "Sales Rep", visible: false},
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    }
                };
                //Secondary Grid
                self.detailSelectionActions = [
                    { name: "add-secondary", text: "Add Secondary Files", requireSelection: true, disabled: function(e) { return !self.localSecurity.AllowSecondaryFileCreate ? "Access Restricted" : false; } },
                    { name: "edit", text: "Edit", requireSelection: true }
                ],

                self.detailGridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File #",
                            showInColumnChooser: false,
                            minWidth: 150
                        },
                        { dataField: "buyer", dataType: "string" },
                        DxGridUtils.dateColumn({
                            dataField: "closeDate",
                            minWidth: 100,
                            width: 120,
                        }),
                        { caption: "County", dataField: "countyName", dataType: "string" },
                        { dataField: "friendlyName", dataType: "string" },
                        DxGridUtils.dateColumn({
                            dataField: "openDate",
                            minWidth: 100,
                            width: 120,
                         }),
                        {
                            dataField: "address1",
                            dataType: "string",
                            minWidth: 200
                        },
                        { dataField: "seller", dataType: "string" },
                        { dataField: "state", dataType: "string" },
                        {
                            dataField: "statusID",
                            dataType: "number",
                            caption: "Status",
                            calculateSortValue: "Status",
                            lookup: {
                                dataSource: _.filter(self.lookupHelpers.getLookupItems(self.lookupItems.STATUSES).slice(OrderStatus.New), s => s.name !== 'PaperlessCloser'),
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        //hidden
                        {
                            caption: "Branch",
                            dataField: "branchID",
                            dataType: "number",
                            lookup: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource
                            },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", {class:"text-truncate"}, props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto text-truncate" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            alignment: "left",
                            calculateDisplayValue: "branch",
                            calculateSortValue: "branch",
                            visible: false
                        },
                        DxGridUtils.dateColumn({
                            dataField: "fundDate",
                            minWidth: 100,
                            width: 120,
                            visible: false
                        }),
                        {
                            caption: "Region",
                            dataField: "regionID",
                            dataType: "number",
                            lookup: {
                                valueExpr: "regionID",
                                displayExpr: "displayName",
                                dataSource: self.regions.slice()
                            },
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            showInColumnChooser: true,
                            visible: false
                        },
                        {
                            dataField: "orderCategoryID", caption: "Category",
                            dataType: "number",
                            lookup: {
                                valueExpr: "id",
                                displayExpr: "name",
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ORDER_CATEGORIES)
                            },
                            calculateSortValue: "orderCategory",
                            visible: false
                        },
                        {
                            dataField: "isGlobal",
                            dataType: "boolean",
                            caption: "Global",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        { caption: "Other Co File #", dataField: "otherCompaniesFileNumber", dataType: "string", visible: false },
                        { caption: "Secondary ID #", dataField: "fileNumber", dataType: "string", visible: false },
                        { dataField: "closingAgent", dataType: "string", visible: false },
                        { dataField: "escrowUnit", dataType: "string", visible: false },
                        { dataField: "escrowOfficer", dataType: "string", visible: false },
                        { dataField: "escrowProcessor", dataType: "string", visible: false },
                        { dataField: "titleUnit", dataType: "string", visible: false },
                        { dataField: "titleOfficer", dataType: "string", visible: false },
                        { dataField: "titleProcessor", dataType: "string", visible: false },
                        { dataField: "salesRepresentative", dataType: "string", caption: "Sales Rep", visible: false},
                    ],
                };

                self.detailGridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.detailItems);
                    }
                };
            },
            onActivateTab({ index, prevIndex }) {
                if (index == 0 && prevIndex == 1) {
                    this.gridInstance.updateDimensions();
                    this.$nextTick(() => {
                        this.gridInstance.repaint();
                    });
                }
            },
            onCloseTab(e) {
                this.activeTabIndex = 0;
                this.showSecondaryTab = false;
            },
            onGridAction(e) {
                const self = this;
                let ordersID = _.get(e, "data.ordersID") || 0;
                let gfNo = _.get(e, "data.gfNo");
                if (ordersID == 0) return;
                switch(e.action.name) {
                    case "view-secondary":
                        self.fetchSecondaryData(ordersID, gfNo);
                        break;
                    case "add-secondary":
                        self.showCreateSecondaryModal(ordersID);
                        break;
                    case "edit":
                        self.$rq.goToFile(ordersID);
                        break;
                }
            },
            onGridRowDoubleClick(e) {
                if (e.data.ordersID > 0) this.$rq.goToFile(e.data.ordersID);
            },
            onTabsChanged(currentTabs, previousTabs) {
                if(!this.showSecondaryTab || currentTabs.length <= previousTabs.length) return;
                this.$nextTick(() => {
                    this.activeTabIndex = currentTabs.length - 1;
                });
            },
            refresh() {
                if(_.isEmpty(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            refreshSecondary() {
                if(_.isEmpty(this.detailGridInstance)) return;
                this.detailGridInstance.clearSelection();
                this.detailGridInstance.refresh();
            },

            async showCreateSecondaryModal(ordersID){
                const self = this;
                let promise = self.$api.OrdersApi.getSecondaryFileMerge(ordersID, 'Create');
                let result = await self.$rqBusy.wait(promise);
                let settings = new SecondaryFileMergeDto(result);
                let title = "Create New Secondary Files";
                self.$dialog.open({
                    title: title,
                    width: 800,
                    height: 610,
                    adaptive: false,
                    component: NewSecondaryFileDialog,
                    props: {
                        settings
                    },
                    onOk: async (e) => {
                        return e.component.save()
                            .then(data => {
                                if (self.showSecondaryTab) self.fetchSecondaryData(self.selectedItemOrdersID, self.selectedPrimaryGfno);
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                return false;
                            });
                    }
                });
            },
        }
    };
</script>