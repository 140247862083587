<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="recordingDashboard"
            id="dx-recording-dashboard"
            automation_id="dg_recording_dashboard"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            @navigate="navigateToOrder"
            @action="onAction"
            v-model:search-value="searchTerm"
            fixed-header
            integrated-search
            remote-search
            force-search-refresh
            :rq-filters="{ mode: 'panel', visibleColumnsOnly: true }">
            <template #filter-panel-header>
                <div class="summary-display">
                    <div class="summary-item">
                        <div class="item-value">{{sentCount}}</div>
                        <div class="item-label">Sent</div>
                    </div>
                    <div class="summary-item">
                        <div class="item-value">{{notSentCount}}</div>
                        <div class="item-label">Not Sent</div>
                    </div>
                    <div class="summary-item">
                        <div class="item-value text-danger">{{rejectedCount}}</div>
                        <div class="item-label">Rejected</div>
                    </div>
                </div>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DxFilterBuilder } from "@/shared/utilities";
    import { SearchRequest } from "@/shared/models/models";

    export default {
        name:"RecordingDashboard",
        props: {
            gridHeight: { default: "100%" },
            gridWidth: { default: "100%" }
        },
        data () {
            return {
                gridDataSource: [],
                selectionActions: [
                    { eventName: "navigate", text: "Go to File", requireSelection: true }
                ],
                rejectedCount: -1,
                sentCount: -1,
                notSentCount: -1,
                searchTerm: ""
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup"
            ]),
            baseFilter() { return []; },
        },
        created() {
            const self = this;
            let columns = [
                {
                    dataField: "documentType", minWidth: 150,
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.RECORDING_DOCUMENT_TYPES)
                    },
                    calculateSortValue: "documentTypeName"},
                {
                    dataField: "fileNumberText",
                    caption: "File #",
                    dataType: "string",
                    minWidth: 150
                },
                { dataField: "address", dataType: "string", minWidth: 150 },
                {
                    dataField: "recordingDocumentStatus",
                    minWidth: 150,
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.RECORDING_STATUSES)
                    },
                    calculateSortValue: "recordingDocumentStatusName"
                },
                DxGridUtils.dateColumn({
                    dataField: "submittedDate",
                    minWidth: 100,
                    width: 120,
                }), {
                    dataField: "state",
                    dataType: "string",
                    minWidth: 150,
                    rqFilter: {
                        displayExpr: "name",
                        valueExpr: "id",
                        dataSource: self.usStatesLookup,
                        maxDisplayedTags: 2,
                        multiTagTemplate: items => ({
                            text: `${items.length} states`,
                            title: _.join(_.map(items, "name"), ", ")
                        })
                    }
                },
                {
                    dataField: "countyId",
                    caption: "County",
                    dataType: "number",
                    calculateDisplayValue: "county",
                    alignment: "left",
                    rqFilter: {
                        displayExpr: "name",
                        valueExpr: "id",
                        dependencyDataField: "state",
                        dataSource: {
                            load(opts, rawSelectedItems, filterExpr=[]) {
                                let stateFilterValue = self.parseStateFilter(filterExpr);
                                if(_.isEmpty(stateFilterValue)) return [];
                                let stateCounties = self.lookupHelpers.getCountiesInStates(stateFilterValue);
                                return _.isEmpty(opts?.searchValue)
                                    ? stateCounties
                                    : _.filter(stateCounties, c => _.includes(_.toLower(c.name), _.toLower(opts?.searchValue)));
                            }
                        },
                        inputDisabled: ({ filters }) => {
                            let stateFilterValue = self.parseStateFilter(filters);
                            return _.isEmpty(stateFilterValue)
                        },
                        filterType: "tags"
                    }
                },
                { dataField: "lender", dataType: "string", minWidth: 150, visible: false },
                { dataField: "city", dataType: "string", minWidth: 150, visible: false },
                {
                    dataField: "recordingType",
                    visible: false,
                    minWidth: 150,
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.RECORDING_TYPES)
                    },
                    calculateSortValue: "recordingTypeName"
                },
                DxGridUtils.dateColumn({
                    visible: false,
                    dataField: "rejectedDate",
                    minWidth: 100,
                    width: 120,
                }),
                {
                    dataField: "transactionType",
                    visible: false,
                    minWidth: 150,
                    dataType: "number",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getAllLookupItems(self.lookupItems.TRANSACTION_TYPES)
                    },
                    calculateSortValue: "transactionTypeName"
                },
                { dataField: "friendlyName", dataType: "string", minWidth: 150, visible: false }
            ];
            self.gridConfig = {
                columns,
                columnMinWidth: 100,
                wordWrapEnabled: false,
                noDataText: "No Documents Found",
                columnAutoWidth: true,
                columnFixing: { enabled: true, columnHidingEnabled: true },
                height: self.gridHeight,
                selection: { mode: "single", requireSelection: true, allowSelectAll: false },
                remoteOperations: { paging: true, sorting: true, filtering: true }
            };
            self.gridDataSource = {
                key: "recordingInformationID",
                load: self.fetchData
            };
        },
        methods: {
            fetchData(loadOptions) {
                const self = this;
                let emptyResult = { data: [], totalCount: 0 };
                let request = SearchRequest.fromLoadOptions(loadOptions);
                request.searchTerm = self.searchTerm;
                let apiPromise = _.invoke(self.$api.RecordingInformationApi, "getAll", request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.rejectedCount = response.summary[1];
                        self.sentCount = response.summary[0];
                        self.notSentCount = response.summary[2];
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(err => {
                        console.error(err);
                        return emptyResult;
                    });
            },

            navigateToOrder(e) {
                if (!e.data || _.lte(e.data.fileNumber, 0)) return;
                this.$router.push(`/order/${e.data.fileNumber}/recording`);
            },

            getRequestFilter(loadOptions) {
                const self = this;
                let filterBuilder = new DxFilterBuilder(loadOptions);
                if(!_.isEmpty(self.baseFilter))
                    filterBuilder.append(self.baseFilter);
                return filterBuilder.filterExpression;
            },

            parseStateFilter(filterExpr) {
                let filterValue = [];
                let parseFilter = expr => {
                    _.forEach(expr, (item, index, arr) => {
                        if(_.isArray(item)) {
                            parseFilter(item);
                        }
                        else if(item === "state") {
                            filterValue.push(arr[2]);
                        }
                    });
                }
                parseFilter(filterExpr);
                return filterValue;
            }
        }
    };
</script>