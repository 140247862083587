<template>
    <component
        ref="currentStep"
        :is="currentStep.component"
        :mapping="currentMapping"        
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import CommitmentPolicyHeaderMapping from "./CommitmentPolicyHeaderMapping"
    import CommitmentPolicyDetailMapping from "./CommitmentPolicyDetailMapping"
    import CommitmentPolicyAssignedLanguageMapping from "./CommitmentPolicyAssignedLanguageMapping"

    export default {
        name: "TitleProductionMapping",
        props: {
            stepNumber: { type: Number, default: 6 },
            mapping: { type: Object, default: () => ({}) },
        },
        components: {
            CommitmentPolicyHeaderMapping,
            CommitmentPolicyDetailMapping,
            CommitmentPolicyAssignedLanguageMapping
        },
        data() {
            return { 
                //selectedAll: false,
                index: 0,
                maxIndex: 0,
                currentStep: {},
                currentMapping: {},
                detailProcessed: false,
                assignedLanguageProcessed: false
            }
        },
        
        created() {

            this.titleProductionMapping.processHeader = !_.isEmpty(this.titleProductionMapping.commitmentPolicyHeaderDataMapping);
            this.titleProductionMapping.processDetail = !_.isEmpty(this.titleProductionMapping.commitmentPolicyDetailDataMapping);
            this.titleProductionMapping.processAssignedLanguage = !_.isEmpty(this.titleProductionMapping.pendingPolicyAssignedLanguageDataMapping);

            this.steps = [
                { title: "Commitment Header", component: "CommitmentPolicyHeaderMapping", visible: this.titleProductionMapping.processHeader },
                { title: "Commitment Detail", component: "CommitmentPolicyDetailMapping", visible: this.titleProductionMapping.processDetail },
                { title: "Commitment Assigned Language", component: "CommitmentPolicyAssignedLanguageMapping", visible: this.titleProductionMapping.processAssignedLanguage },
            ];
            
            this.maxIndex = _.findLastIndex(this.steps, function(s) { return s.visible === true; });

            if (this.titleProductionMapping.processHeader) {
                this.currentStep = this.steps[0];
                this.currentMapping = this.titleProductionMapping.commitmentPolicyHeaderDataMapping;            
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            titleProductionMapping(){
                return this.mapping.titleProductionMapping || null;
            },
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All"},
        },

        methods:{
            onMoveNext(){
                let currentStep =_.get(this, "$refs.currentStep", null);              
                if (_.isFunction(currentStep.onMoveNext) && !_.invoke(currentStep, "onMoveNext"))
                    return;

                if (this.titleProductionMapping.processDetail && !this.detailProcessed) {
                    this.detailProcessed = true;
                    this.currentStep = this.steps[1];
                    this.currentMapping = this.titleProductionMapping.commitmentPolicyDetailDataMapping;
                    return false;
                }
                if (this.titleProductionMapping.processAssignedLanguage && !this.assignedLanguageProcessed) {
                    this.assignedLanguageProcessed = true;
                    this.currentStep = this.steps[2];
                    this.currentMapping = this.titleProductionMapping;
                    return false;
                }

                return true;
            },

            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },

            setOverrides(value){
                // Dev note: commented these out here and in the UI because they appear to be unneeded currently.
                // However, all of the data from the PCStageCommitmentHeaderData table is avalailable if we need it later.
                // Just need to uncomment as needed.

                //this.titleProductionMapping.acceptStageBinderDate = value;
                //this.titleProductionMapping.acceptStageBinderNumber = value;
                //this.titleProductionMapping.acceptStageCommitmentNumber = value;
                //this.titleProductionMapping.acceptStageCountyID = value;
                //this.titleProductionMapping.acceptStageExceptions = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyDate = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyNumber = value;
                //this.titleProductionMapping.acceptStageOwnerPolicyNumberPoolID = value;                
                //this.titleProductionMapping.acceptStageOwnerRemitDate = value;                
                //this.titleProductionMapping.acceptStageOwnerScheduleB = value;
                //this.titleProductionMapping.acceptStageOwnerScheduleBOverride = value;                
                //this.titleProductionMapping.acceptStagePropertyTypeID = value;
                //this.titleProductionMapping.acceptStageSignatory = value;                
                this.titleProductionMapping.acceptStageCurrentHolders = value;
                this.titleProductionMapping.acceptStageCurrentHoldersOverride = value;
                this.titleProductionMapping.acceptStageEffectiveDate = value;
                this.titleProductionMapping.acceptStageInterestInLand = value;
                this.titleProductionMapping.acceptStageInterestInLandOverride = value;
                this.titleProductionMapping.acceptStageIssueDate = value;
                this.titleProductionMapping.acceptStageOwnerInsured = value;
                this.titleProductionMapping.acceptStageOwnerInsuredOverride = value;
                this.titleProductionMapping.acceptStageOwnerRemitDateLock = value;
                this.titleProductionMapping.acceptStageOwnerStrikeException2 = value;
                this.titleProductionMapping.acceptStageStandbyYear = value;
                this.titleProductionMapping.acceptStageStrikeO1 = value;
                this.titleProductionMapping.acceptStageStrikeO2 = value;
                this.titleProductionMapping.acceptStageStrikeO3 = value;
                this.titleProductionMapping.acceptStageStrikeO4 = value;
                this.titleProductionMapping.acceptStageStrikeO5 = value;
                this.titleProductionMapping.acceptStageStrikeO6 = value;
                this.titleProductionMapping.acceptStageStrikeO7 = value;
                this.titleProductionMapping.acceptStageStrikeO8 = value;
                this.titleProductionMapping.acceptStageStrikeO9 = value;
            }
        }
    }
</script>
