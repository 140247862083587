<template>
    <div class="rq-container">
        <div class="row">
            <div class="col-3">
                <div class="step-title">Step {{stepNumber}}</div>
                <div class="rq-title rq-title-lg p-0">LOAN</div>
                <div class="step-text">Loan {{loanIndex+1}} of {{loanMappings.length}}</div>
            </div>
            <div class="col-9">
                <table class="table table-sm table-borderless table-secondary">
                    <tbody>
                        <tr>
                            <th>Loan Order #</th>
                            <td>{{originalLoanData.loanOrder}}</td>
                            <th>Loan Amount</th>
                            <td>{{formatMoney(originalLoanData.amount)}}</td>
                        </tr>
                        <tr>
                            <th>Lender ID</th>
                            <td>{{originalLoanData.lenderID}}</td>
                            <th>Monthly Principal</th>
                            <td>{{formatMoney(originalLoanData.monthlyPrincipal)}}</td>
                        </tr>
                        <tr>
                            <th>Lender</th>
                            <td>{{originalLoanData.lenderName}}</td>
                            <th>Monthly Interest</th>
                            <td>{{originalLoanData.monthlyInterest}} %</td>
                        </tr>
                        <tr>
                            <th>Loan Number</th>
                            <td>{{originalLoanData.number}}</td>
                            <th>Interest Rate</th>
                            <td>{{originalLoanData.interestRate}} %</td>
                        </tr>
                        <tr>
                            <th>Mort. Ins. Case #</th>
                            <td>{{originalLoanData.mortInsCaseNumber}}</td>
                            <th>Term</th>
                            <td>{{originalLoanData.term}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-2">Create New</div>
            <div class="col" v-rq-tooltip.hover.left>
                <rq-switch
                    automation_id="chk_create_new"
                    type="text"
                    variant="primary"
                    on="Yes"
                    off="No"
                    size="sm"
                    ref="newLoanSetting"
                    v-model="loanMapping.isNewLoan"
                    @change="onChangeNewLoan"
                />
            </div>
        </div>
        <table class="table rq-table">
            <thead>
                <tr>
                    <td>Field Name</td>
                    <td>Current Data</td>
                    <td>Pending Data</td>
                    <td>Override?</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Loan Number</td>
                    <td>{{loanMapping.finalNumber}}</td>
                    <td>{{loanMapping.stageNumber}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_loan_number"
                            v-model="loanMapping.acceptStageNumber"
                            :disabled="!canAcceptStageNumber"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td>{{formatMoney(loanMapping.finalAmount)}}</td>
                    <td>{{formatMoney(loanMapping.stageAmount)}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_amount"
                            v-model="loanMapping.acceptStageAmount"
                            :disabled="!canAcceptStageAmount"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Interest Rate</td>
                    <td>{{loanMapping.finalInterestRate}} %</td>
                    <td>{{loanMapping.stageInterestRate}} %</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_rate"
                            v-model="loanMapping.acceptStageInterestRate"
                            :disabled="!canAcceptStageInterestRate"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Case Number</td>
                    <td>{{loanMapping.finalMortInsCaseNumber}}</td>
                    <td>{{loanMapping.stageMortInsCaseNumber}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_case_number"
                            v-model="loanMapping.acceptStageMortInsCaseNumber"
                            :disabled="!canAcceptStageMortInsCaseNumber"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Loan Order</td>
                    <td>{{loanMapping.finalLoanOrder}}</td>
                    <td>{{loanMapping.stageLoanOrder}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_loan_order"
                            v-model="loanMapping.acceptStageLoanOrder"
                            :disabled="!canAcceptStageLoanOrder"
                        />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">
                        <div class="text-right">
                            <b-btn
                                automation_id="btn_select_all"
                                variant="action-link"
                                v-model="selectedAll"
                                @click="onToggleSelectAll">{{selectAllLabel}}
                            </b-btn>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "LoanMapping",
        props: {
            stepNumber: { type: Number, default: 2 },
            mapping: { type: Object, default: () => ({}) },
        },
        watch: {
            loanIndex: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.originalLoanData = this.originalMappingData[newValue];
                },
                immediate: true
            },
        },
        data() {
            return {
                loanIndex: 0,
                originalMappingData: _.cloneDeep(this.mapping.loanMappings),
                originalLoanData: {},
                selectedAll: false
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            tooltips() {
                return {
                    isNewDisabled: this.disableNewFile ? "Override checkbox has been clicked. Create New is inactive." : "",
                };
            },
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All"},
            disableNewFile() {
                return this.loanMapping.acceptStageNumber
                    || this.loanMapping.acceptStageLoanOrder
                    || this.loanMapping.acceptStageAmount
                    || this.loanMapping.acceptStageInterestRate
                    || this.loanMapping.acceptStageMortInsCaseNumber
                    || this.loanMapping.acceptStageLoanPolicyLiability
                    || this.loanMapping.acceptStageTerm
                    || this.loanMapping.acceptStageLoanTermPeriod
                    || this.loanMapping.acceptStageMonthlyPrincipal
                    || this.loanMapping.acceptStageMonthlyInterest;
            },
            maxIndex() { return this.loanMappings.length; },
            loanMappings() {
                return this.mapping.loanMappings || [];
            },
            loanMapping() { return this.mapping.loanMappings[this.loanIndex]; },
            canAcceptStageNumber(){
                let value = _.get(this.loanMapping, "stageNumber", null);
                if (value) return true;
                return false;
            },
            canAcceptStageLoanOrder() {
                let value = _.get(this.loanMapping, "stageLoanOrder", null);
                return !_.isNil(value);
            },
            canAcceptStageAmount() {
                let value = _.get(this.loanMapping, "stageAmount", null);
                return !_.isNil(value);
            },
            canAcceptStageInterestRate() {
                let value = _.get(this.loanMapping, "stageInterestRate", null);
                return !_.isNil(value);
            },
            canAcceptStageMortInsCaseNumber() {
                let value = _.get(this.loanMapping, "stageMortInsCaseNumber", null);
                return !_.isNil(value);
            }
        },

        methods:{
            onChangeNewLoan() {
                if (this.loanMapping.isNewLoan) {
                    // this.clearLoanData();
                    this.selectedAll = true;
                    this.setOverrides(this.selectedAll);
                }
                else {
                    this.resetOriginalData();
                }
            },
            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },
            clearLoanData() {
                this.loanMapping.number = null;
                this.loanMapping.loanOrder = null;
                this.loanMapping.amount = null;
                this.loanMapping.interestRate = null;
                this.loanMapping.mortInsCaseNumber = null;
            },
            resetOriginalData() {
                this.loanMapping.number = this.originalLoanData.number;
                this.loanMapping.loanOrder = this.originalLoanData.loanOrder;
                this.loanMapping.amount = this.originalLoanData.amount;
                this.loanMapping.interestRate = this.originalLoanData.interestRate;
                this.loanMapping.mortInsCaseNumber = this.originalLoanData.mortInsCaseNumber;
                this.setOverrides(false);
            },
            setOverrides(value) {
                if (this.canAcceptStageNumber)
                    this.loanMapping.acceptStageNumber = value;

                if (this.canAcceptStageLoanOrder)
                    this.loanMapping.acceptStageLoanOrder = value;

                if (this.canAcceptStageAmount)
                    this.loanMapping.acceptStageAmount = value;

                if (this.canAcceptStageInterestRate)
                    this.loanMapping.acceptStageInterestRate = value;

                if (this.canAcceptStageMortInsCaseNumber)
                    this.loanMapping.acceptStageMortInsCaseNumber = value;

                // Potentially want additional attributes below
                // this.loanMapping.acceptStageLoanPolicyLiability = value;
                // this.loanMapping.acceptStageTerm = value;
                // this.loanMapping.acceptStageLoanTermPeriod = value;
                // this.loanMapping.acceptStageMonthlyPrincipal = value;
                // this.loanMapping.acceptStageMonthlyInterest = value;
            },
            onMoveNext(){
                this.loanIndex++;
                if (this.loanIndex < this.maxIndex) {
                    return false;
                }
                return true;
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"-%s%v", zero:"%s%v" } }); },
        }
    }
</script>
