<template>
    <div class="content-wrapper">
        <recent-orders />
    </div>
</template>
<script>
    import RecentOrders from "../components/RecentOrders";

    export default {
        components: {
            RecentOrders
        },
        computed: {
        },
        methods: {
        }
    }
</script>