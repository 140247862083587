<template>
    <div class="container-fluid pt-2">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div v-for="(color,index) in colors" :key="index" xs="12" class="mb-4 col-sm-6 col-md-4 col-xl-2">
                        <div class="theme-color w-75 rounded mb-3" :style="{ 'padding-top': '75%', 'background-color':color.hex }"></div>
                        <h6>{{color.label}}</h6>
                        <table class="w-100">
                            <tbody>
                                <tr>
                                    <td class="text-muted">HEX:</td>
                                    <td class="font-weight-bold">{{color.hex}}</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">RGB:</td>
                                    <td class="font-weight-bold">{{color.rgb}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return { 
                colors: [
                    { label: "Teal", hex: "#28A199", rgb: "rgb(40, 161, 153)" },
                    { label: "Light Teal", hex: "#D8EFEF", rgb: "rgb(216, 239, 239)" },
                    { label: "Alt Teal", hex: "#BDE4E4", rgb: "rgb(189, 228, 228)" },
                    { label: "Yellow", hex: "#E8B71F", rgb: "rgb(232, 183, 31)" },
                    { label: "Orange", hex: "#F36A22", rgb: "rgb(243, 106, 34)" },
                    { label: "Purple", hex: "#786EB2", rgb: "rgb(120, 110, 178)" },
                    { label: "Blue", hex: "#209AD6", rgb: "rgb(32, 154, 214)" },
                    { label: "Red", hex: "#F05153", rgb: "rgb(240, 81, 83)" },
                    { label: "Light Red", hex: "#F48585", rgb: "rgb(244, 133, 133)" },
                    { label: "Success", hex: "#7FA521", rgb: "rgb(127, 165, 33)" },
                    { label: "Warn/Warning", hex: "#ED7C23", rgb: "rgb(237, 124, 35)" },
                    { label: "Error/Danger", hex: "#EF3C47", rgb: "rgb(239, 60, 71)" }
                ]
            };
        }
    };
</script>