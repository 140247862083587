<template>
    <rqdx-action-data-grid
        ref="ordersDataGrid"
        id="dx-recent-orders"
        automation_id="dg_recent_orders"
        :actions="selectionActions"
        :data-source="gridDataSource"
        :config="gridConfig"
        export-file-name="RecentFiles-Grid-State"
        class="grid-container-sm"
        @rowDoubleClick="navigateToOrder"
        @navigate="navigateToOrder"
        integrated-search
        rq-filters>
        <template #toolbar>
            <div class="rq-title rq-title-lg">My Files</div>
            <ul class="nav ms-2 me-auto">
                <li class="nav-item">
                    <b-form-checkbox
                        v-model="companyFiles"
                        @change="onCompanyFilesChecked"
                        switch>Company Files
                    </b-form-checkbox>
                </li>
            </ul>
        </template>
        <div v-show="noData" class="rq-no-data-panel">
            <div class="message">No files found.
                <div class="message_subtext" v-if="companyFiles">Toggle button above to see my company file.</div>
                <div class="message_subtext" v-if="!companyFiles">Toggle button above to see company file.</div>
            </div>
        </div>
        <div v-show="noFilteredData" class="rq-no-data-panel">
            <div class="message">No recent files found matching the selected filters.</div>
            <b-btn variant="link" class="btn-theme" @click="onClearGridFilters">Clear Grid Filters</b-btn>
        </div>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DxFilterBuilder } from "@/shared/utilities";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import { OrderStatus } from "@/shared/models/enums";
    import BranchItemTemplate from "./BranchItemTemplate.vue";

    export default {
        name:"RecentOrders",
        props: {
            gridHeight: { default: "100%" },
            gridWidth: { default: "100%" }
        },
        data () {
            return {
                gridDataSource: [],
                selectionActions: [
                    { eventName: "navigate", text: "Go to File", requireSelection: true }
                ],
                hasUserFilters: false,
                totalCountValue: -1,
                companyFiles: false
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            baseFilter() { return []; },
            gridInstance () { return _.get(this, "$refs.ordersDataGrid.gridInstance", {}) || {}; },
            regions() { return this.lookupHelpers.getRegions(); },
            noData() { return this.totalCountValue === 0 && !this.hasUserFilters; },
            noFilteredData() { return this.totalCountValue === 0 && this.hasUserFilters; }
        },
        created() {
            const self = this;
            //RQO-17544 - moved to the backend
            // let propertyAddressCellTemplate = (cellElement, cellInfo) => {
            //     let rowData = cellInfo.data || {};
            //     let addressValues = [
            //         rowData.address1,
            //         rowData.address2,
            //         rowData.city,
            //         rowData.state,
            //         rowData.zip
            //     ];
            //     let address = _.joinParts(addressValues, ", ")
            //     cellElement.attr("title", address)
            //     $("<span/>")
            //         .text(address)
            //         .appendTo(cellElement);
            // };
            let branchDataSource = _.map(self.allBranches, b => {
                let branchItem = _.clone(b);
                let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                branchItem.regionDisplay = _.isNil(branchRegion)
                    ? "Region not found"
                    : branchRegion.description;
                return branchItem;
            });
            let columns = [
                {
                    dataField: "gfNo",
                    caption: "File #",
                    dataType: "string",
                    showInColumnChooser: false,
                    minWidth: 150
                },
                { dataField: "closingAgent", dataType: "string", minWidth: 100 },
                DxGridUtils.dateColumn({
                    dataField: "closeDate",
                    minWidth: 100,
                    width: 120,
                }),
                DxGridUtils.dateColumn({
                    dataField: "fundDate",
                    minWidth: 100,
                    width: 120,
                }),
                {
                    caption: "Branch",
                    dataField: "branchID",
                    dataType: "string",
                    rqFilter: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: branchDataSource,
                        itemTemplate: BranchItemTemplate
                    },
                    alignment: "left",
                    calculateDisplayValue: "branch",
                    calculateSortValue: "branch"
                },
                {
                    dataField: "propertyAddress",
                    dataType: "string",
                    caption: "Property Address",
                    minWidth: 200
                },
                { dataField: "buyer", dataType: "string" },
                { dataField: "seller", dataType: "string" },
                {
                    dataField: "orderCategoryID", caption: "Category",
                    dataType: "string",
                    lookup: {
                        valueExpr: "id",
                        displayExpr: "name",
                        dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ORDER_CATEGORIES)
                    },
                    calculateSortValue: "orderCategory",
                    visible: false
                },
                {
                    dataField: "statusID",
                    dataType: "string",
                    caption: "Status",
                    calculateSortValue: "Status",
                    lookup: {
                        dataSource: _.filter(self.lookupHelpers.getLookupItems(self.lookupItems.STATUSES).slice(OrderStatus.New), s => s.name !== 'PaperlessCloser'),
                        valueExpr: "id",
                        displayExpr: "name"
                    },
                    visible: false
                },
                DxGridUtils.dateColumn({
                    dataField: "openDate",
                    visible: false
                }),
                {
                    caption: "Region",
                    dataField: "regionID",
                    dataType: "string",
                    lookup: {
                        valueExpr: "regionID",
                        displayExpr: "displayName",
                        dataSource: self.regions.slice()
                    },
                    calculateSortValue: DxGridUtils.regionDisplaySortValue,
                    visible: false,
                    showInColumnChooser: true
                },
                {
                    dataField: "isGlobal",
                    caption: "Global",
                    cellTemplate: DxGridUtils.boolCellTemplate,
                    visible: false

                },
                { dataField: "escrowUnit", visible: false },
                { dataField: "escrowOfficer", visible: false },
                { dataField: "escrowProcessor", visible: false },
                { dataField: "titleUnit", visible: false },
                { dataField: "titleOfficer", visible: false },
                { dataField: "titleProcessor", visible: false },
                { dataField: "salesRepresentative", caption: "Sales Rep", visible: false}
            ];
            self.gridConfig = {
                columns,
                columnMinWidth: 100,
                wordWrapEnabled: false,
                columnAutoWidth: false,
                columnFixing: { enabled: true, columnHidingEnabled: true },
                height: self.gridHeight,
                selection: { mode: "single", allowSelectAll: false },
                remoteOperations: { paging: true, sorting: true, filtering: true }
            };
            self.gridDataSource = {
                key: "orderId",
                load: self.fetchData
            };
            self.companyFiles = localStorage.companyFiles || self.companyFiles;
        },
        methods: {
            fetchData(loadOptions) {
                const self = this;
                let emptyResult = { data: [], totalCount: 0 };
                let request = self.getRecentFileRequest(loadOptions);
                let apiPromise = _.invoke(self.$api.OrdersApi, "getRecentOrders", request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.hasUserFilters = !_.isEmpty(loadOptions.filter);
                        self.totalCountValue = response.totalRecords;
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(err => {
                        console.error(err);
                        return emptyResult;
                    });
            },
            navigateToOrder(e) {
                if (!e.data || _.lte(e.data.orderId, 0)) return;
                this.$rq.goToFile(e.data.orderId);
            },
            getRecentFileRequest(loadOptions) {
                const self = this;

                let request = SearchRequest.fromLoadOptions(loadOptions, ["orderId"]);
                request.pagingEnabled = false;
                request.filter = self.getRequestFilter(loadOptions);
                request.parameters["showAllCompany"] = this.companyFiles;
                return request;
            },
            getRequestFilter(loadOptions) {
                const self = this;
                let filterBuilder = new DxFilterBuilder(loadOptions);
                if(!_.isEmpty(self.baseFilter))
                    filterBuilder.append(self.baseFilter);
                return filterBuilder.filterExpression;
            },
            onClearGridFilters() {
                this.gridInstance.clearFilter();
                this.$refs.ordersDataGrid.resetFilter();
            },
            onCompanyFilesChecked() {
                localStorage.companyFiles = this.companyFiles;
                this.gridInstance.refresh();
            }
        }
    };
</script>
<style lang="scss">
    .rq-no-data-panel {
        position: absolute;
        top: 40px;
        left: 0;
        height: calc(100% - 65px);
        width: calc(100% - 3.5rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff; // $white;
        z-index: 10;
        margin: 0 1.75rem 1.5rem 1.75rem;
        border: 1px solid #DAD9D9; // $gray-tertiary;
        text-align: center;
        > .message {
            font-size: 1.5rem;
            font-weight: 300;
            color: #979797; // $gray-600;
        }
        .message_subtext {
            font-size: 1.0rem;
            font-weight: 300;
            color: #979797; // $gray-600;
        }
    }
</style>