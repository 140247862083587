<template>
    <div class="rq-container content-wrapper">
        <div class="row">
            <div class="col-3">
                <div class="step-title">Step {{stepNumber}}</div>
                <div class="rq-title rq-title-lg p-0">{{stepTitle}}</div>
                <div class="step-text">{{stepText}}</div>
            </div>
            <div class="col-9">
                <table class="table table-sm table-borderless table-secondary">
                    <tbody>
                        <tr>
                            <th>Formal Name</th>
                            <td>{{originalBuyerSellerData.formalName}}</td>
                        </tr>
                        <tr>
                            <th>Work Phone</th>
                            <td>{{buyerSellerMapping.finalWorkPhone2}}</td>
                        </tr>
                        <tr>
                            <th>Cell Phone</th>
                            <td>{{buyerSellerMapping.finalCellPhone1}}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{buyerSellerMapping.finalEmailAddress1}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-2">Create New</div>
            <div class="col" v-rq-tooltip.hover.left>
                <rq-switch
                    automation_id="chk_create_new"
                    type="text"
                    variant="primary"
                    on="Yes"
                    off="No"
                    size="sm"
                    ref="newPropertySetting"
                    v-model="buyerSellerMapping.isNewBuyerSeller"
                    @change="onChangeNewBuyerSeller"
                />
            </div>
        </div>
        <div class="wizard-table-section">
            <div class="rq-table-container rq-table-container-bordered">
                <rq-scroll-container
                    ref="psElement"
                    ps-class-attr="rq-table-scroll-container"
                    @updated="onScrollUpdated"
                    perfect-scrollbar
                    update-on-resize>
                    <table ref="tableElement" class="rq-table">
                        <thead>
                            <tr>
                                <td>Field Name</td>
                                <td>Current Data</td>
                                <td>Pending Data</td>
                                <td>Override?</td>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Business Name</td>
                                <td>{{buyerSellerMapping.finalBusinessName}}</td>
                                <td>{{buyerSellerMapping.stageBusinessName}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_businessName"
                                        v-model="buyerSellerMapping.acceptStageBusinessName"
                                        :disabled="!canAcceptStageBusinessName"
                                    />
                                </td>
                            </tr>

                            <!-- <tr>
                                <td>buyerSellerRelationshipID</td>
                                <td>{{buyerSellerMapping.finalBuyerSellerRelationshipID}}</td>
                                <td>{{buyerSellerMapping.stageBuyerSellerRelationshipID}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_buyerSellerRelationshipID"
                                        v-model="buyerSellerMapping.acceptStageBuyerSellerRelationshipID"
                                    />
                                </td>
                            </tr> -->

                            <tr>
                                <td>Cell Phone 1</td>
                                <td>{{buyerSellerMapping.finalCellPhone1}}</td>
                                <td>{{buyerSellerMapping.stageCellPhone1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_cellPhone1"
                                        v-model="buyerSellerMapping.acceptStageCellPhone1"
                                        :disabled="!canAcceptStageCellPhone1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Cell Phone 2</td>
                                <td>{{buyerSellerMapping.finalCellPhone2}}</td>
                                <td>{{buyerSellerMapping.stageCellPhone2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_cellPhone2"
                                        v-model="buyerSellerMapping.acceptStageCellPhone2"
                                        :disabled="!canAcceptStageCellPhone2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Current Address 1</td>
                                <td>{{buyerSellerMapping.finalCurrentAddress1}}</td>
                                <td>{{buyerSellerMapping.stageCurrentAddress1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_currentAddress1"
                                        v-model="buyerSellerMapping.acceptStageCurrentAddress1"
                                        :disabled="!canAcceptStageCurrentAddress1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Current City</td>
                                <td>{{buyerSellerMapping.finalCurrentCity}}</td>
                                <td>{{buyerSellerMapping.stageCurrentCity}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_currentCity"
                                        v-model="buyerSellerMapping.acceptStageCurrentCity"
                                        :disabled="!canAcceptStageCurrentCity"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Current State</td>
                                <td>{{buyerSellerMapping.finalCurrentState}}</td>
                                <td>{{buyerSellerMapping.stageCurrentState}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_currentState"
                                        v-model="buyerSellerMapping.acceptStageCurrentState"
                                        :disabled="!canAcceptStageCurrentState"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Current Zip</td>
                                <td>{{buyerSellerMapping.finalCurrentZip}}</td>
                                <td>{{buyerSellerMapping.stageCurrentZip}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_currentZip"
                                        v-model="buyerSellerMapping.acceptStageCurrentZip"
                                        :disabled="!canAcceptStageCurrentZip"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Email Address 1</td>
                                <td>{{buyerSellerMapping.finalEmailAddress1}}</td>
                                <td>{{buyerSellerMapping.stageEmailAddress1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_emailAddress1"
                                        v-model="buyerSellerMapping.acceptStageEmailAddress1"
                                        :disabled="!canAcceptStageEmailAddress1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Email Address 2</td>
                                <td>{{buyerSellerMapping.finalEmailAddress2}}</td>
                                <td>{{buyerSellerMapping.stageEmailAddress2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_emailAddress2"
                                        v-model="buyerSellerMapping.acceptStageEmailAddress2"
                                        :disabled="!canAcceptStageEmailAddress2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Extension 1</td>
                                <td>{{buyerSellerMapping.finalExtension1}}</td>
                                <td>{{buyerSellerMapping.stageExtension1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_extension1"
                                        v-model="buyerSellerMapping.acceptStageExtension1"
                                        :disabled="!canAcceptStageExtension1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Extension 2</td>
                                <td>{{buyerSellerMapping.finalExtension2}}</td>
                                <td>{{buyerSellerMapping.stageExtension2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_extension2"
                                        v-model="buyerSellerMapping.acceptStageExtension2"
                                        :disabled="!canAcceptStageExtension2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Fax 1</td>
                                <td>{{buyerSellerMapping.finalFax1}}</td>
                                <td>{{buyerSellerMapping.stageFax1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_fax1"
                                        v-model="buyerSellerMapping.acceptStageFax1"
                                        :disabled="!canAcceptStageFax1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Fax 2</td>
                                <td>{{buyerSellerMapping.finalFax2}}</td>
                                <td>{{buyerSellerMapping.stageFax2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_fax2"
                                        v-model="buyerSellerMapping.acceptStageFax2"
                                        :disabled="!canAcceptStageFax2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>First Name 1</td>
                                <td>{{buyerSellerMapping.finalFirstName1}}</td>
                                <td>{{buyerSellerMapping.stageFirstName1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_firstName1"
                                        v-model="buyerSellerMapping.acceptStageFirstName1"
                                        :disabled="!canAcceptStageFirstName1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>First Name 2</td>
                                <td>{{buyerSellerMapping.finalFirstName2}}</td>
                                <td>{{buyerSellerMapping.stageFirstName2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_firstName2"
                                        v-model="buyerSellerMapping.acceptStageFirstName2"
                                        :disabled="!canAcceptStageFirstName2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Gender 1</td>
                                <td>{{buyerSellerMapping.finalGender1}}</td>
                                <td>{{buyerSellerMapping.stageGender1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_gender1"
                                        v-model="buyerSellerMapping.acceptStageGender1"
                                        :disabled="!canAcceptStageGender1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Gender 2</td>
                                <td>{{buyerSellerMapping.finalGender2}}</td>
                                <td>{{buyerSellerMapping.stageGender2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_gender2"
                                        v-model="buyerSellerMapping.acceptStageGender2"
                                        :disabled="!canAcceptStageGender2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Home Phone</td>
                                <td>{{buyerSellerMapping.finalHomePhone}}</td>
                                <td>{{buyerSellerMapping.stageHomePhone}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_homePhone"
                                        v-model="buyerSellerMapping.acceptStageHomePhone"
                                        :disabled="!canAcceptStageHomePhone"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Home Phone 2</td>
                                <td>{{buyerSellerMapping.finalHomePhone2}}</td>
                                <td>{{buyerSellerMapping.stageHomePhone2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_homePhone2"
                                        v-model="buyerSellerMapping.acceptStageHomePhone2"
                                        :disabled="!canAcceptStageHomePhone2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Last Name 1</td>
                                <td>{{buyerSellerMapping.finalLastName1}}</td>
                                <td>{{buyerSellerMapping.stageLastName1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_lastName1"
                                        v-model="buyerSellerMapping.acceptStageLastName1"
                                        :disabled="!canAcceptStageLastName1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Last Name 2</td>
                                <td>{{buyerSellerMapping.finalLastName2}}</td>
                                <td>{{buyerSellerMapping.stageLastName2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_lastName2"
                                        v-model="buyerSellerMapping.acceptStageLastName2"
                                        :disabled="!canAcceptStageLastName2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Middle Initial 1</td>
                                <td>{{buyerSellerMapping.finalMiddleInitial1}}</td>
                                <td>{{buyerSellerMapping.stageMiddleInitial1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_middleInitial1"
                                        v-model="buyerSellerMapping.acceptStageMiddleInitial1"
                                        :disabled="!canAcceptStageMiddleInitial1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Middle Initial 2</td>
                                <td>{{buyerSellerMapping.finalMiddleInitial2}}</td>
                                <td>{{buyerSellerMapping.stageMiddleInitial2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_middleInitial2"
                                        v-model="buyerSellerMapping.acceptStageMiddleInitial2"
                                        :disabled="!canAcceptStageMiddleInitial2"
                                    />
                                </td>
                            </tr>

                            <!-- <tr>
                                <td>name2Name1Relation</td>
                                <td>{{buyerSellerMapping.finalName2Name1Relation}}</td>
                                <td>{{buyerSellerMapping.stageName2Name1Relation}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_name2Name1Relation"
                                        v-model="buyerSellerMapping.acceptStageName2Name1Relation"
                                    />
                                </td>
                            </tr> -->

                            <tr>
                                <td>Social Security Number 1</td>
                                <td>{{buyerSellerMapping.finalSocialSecurityNumber1}}</td>
                                <td>{{buyerSellerMapping.stageSocialSecurityNumber1}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_socialSecurityNumber1"
                                        v-model="buyerSellerMapping.acceptStageSocialSecurityNumber1"
                                        :disabled="!canAcceptStageSocialSecurityNumber1"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Social Security Number 2</td>
                                <td>{{buyerSellerMapping.finalSocialSecurityNumber2}}</td>
                                <td>{{buyerSellerMapping.stageSocialSecurityNumber2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_socialSecurityNumber2"
                                        v-model="buyerSellerMapping.acceptStageSocialSecurityNumber2"
                                        :disabled="!canAcceptStageSocialSecurityNumber2"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Work Phone 2</td>
                                <td>{{buyerSellerMapping.finalWorkPhone2}}</td>
                                <td>{{buyerSellerMapping.stageWorkPhone2}}</td>
                                <td>
                                    <b-form-checkbox
                                        automation_id="chk_override_workPhone2"
                                        v-model="buyerSellerMapping.acceptStageWorkPhone2"
                                        :disabled="!canAcceptStageWorkPhone2"
                                    />
                                </td>
                            </tr>
                            <RqTableSpacerRow
                                :height="spacerHeight"
                                :col-count="colCount"
                            />

                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4">
                                    <div class="text-right">
                                        <b-btn
                                            automation_id="btn_select_all"
                                            variant="action-link"
                                            v-model="selectedAll"
                                            @click="onToggleSelectAll">{{selectAllLabel}}
                                        </b-btn>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </rq-scroll-container>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { RqTableSpacerRow, useRqTableSpacer } from "@/shared/components/rq/RqTableSpacerRow";

    export default {
        name: "BuyerSellerMapping",
        props: {
            stepNumber: { type: Number, default: 4 },
            title: {type: String, default: ""},
            mapping: { type: Object, default: () => ({}) },
        },
        components: {
            RqTableSpacerRow
        },
        setup() {
            return useRqTableSpacer();
        },
        data() {
            return {
                index: 0,
                selectedAll: false,
                colCount: 4
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            originalMappingData() {
                return this.isBuyer ? _.cloneDeep(this.mapping.buyerMappings) : _.cloneDeep(this.mapping.sellerMappings);
            },
            stepTitle() { return this.title; },
            stepText() {
                return `${this.stepTitle} ${this.index+1} of ${this.buyerSellerMappings.length}`;
            },
            tooltips() {
                return {
                    isNewDisabled: this.disableNew ? "Override checkbox has been clicked. Create New is inactive." : "",
                };
            },
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All"},
            disableNew() {
                return this.buyerSellerMapping.acceptStageBusinessName
                    || this.buyerSellerMapping.acceptStageCellPhone1
                    || this.buyerSellerMapping.acceptStageCellPhone2
                    || this.buyerSellerMapping.acceptStageCurrentAddress1
                    || this.buyerSellerMapping.acceptStageCurrentCity
                    || this.buyerSellerMapping.acceptStageCurrentState
                    || this.buyerSellerMapping.acceptStageCurrentZip
                    || this.buyerSellerMapping.acceptStageEmailAddress1
                    || this.buyerSellerMapping.acceptStageEmailAddress2
                    || this.buyerSellerMapping.acceptStageExtension1
                    || this.buyerSellerMapping.acceptStageExtension2
                    || this.buyerSellerMapping.acceptStageFax1
                    || this.buyerSellerMapping.acceptStageFax2
                    || this.buyerSellerMapping.acceptStageFirstName1
                    || this.buyerSellerMapping.acceptStageFirstName2
                    || this.buyerSellerMapping.acceptStageGender1
                    || this.buyerSellerMapping.acceptStageGender2
                    || this.buyerSellerMapping.acceptStageHomePhone
                    || this.buyerSellerMapping.acceptStageHomePhone2
                    || this.buyerSellerMapping.acceptStageLastName1
                    || this.buyerSellerMapping.acceptStageLastName2
                    || this.buyerSellerMapping.acceptStageMiddleInitial1
                    || this.buyerSellerMapping.acceptStageMiddleInitial2
                    || this.buyerSellerMapping.acceptStageSocialSecurityNumber1
                    || this.buyerSellerMapping.acceptStageSocialSecurityNumber2
                    || this.buyerSellerMapping.acceptStageWorkPhone2;
            },
            maxIndex() { return this.buyerSellerMappings.length - 1; },
            buyerSellerMappings() { return this.isBuyer ? this.mapping.buyerMappings : this.mapping.sellerMappings; },
            isBuyer() { return this.title === "Buyer"; },
            isSeller() { return !this.isBuyer; },
            buyerSellerMapping() { return this.isBuyer ? this.mapping.buyerMappings[this.index] : this.mapping.sellerMappings[this.index]; },
            canAcceptStageBusinessName() {
                let value = _.get(this.buyerSellerMapping, "stageBusinessName", null);
                return !_.isNil(value);
            },
            canAcceptStageCellPhone1() {
                let value = _.get(this.buyerSellerMapping, "stageCellPhone1", null);
                return !_.isNil(value);
            },
            canAcceptStageCellPhone2() {
                let value = _.get(this.buyerSellerMapping, "stageCellPhone2", null);
                return !_.isNil(value);
            },
            canAcceptStageCurrentAddress1() {
                let value = _.get(this.buyerSellerMapping, "stageCurrentAddress1", null);
                return !_.isNil(value);
            },
            canAcceptStageCurrentCity() {
                let value = _.get(this.buyerSellerMapping, "stageCurrentCity", null);
                return !_.isNil(value);
            },
            canAcceptStageCurrentState() {
                let value = _.get(this.buyerSellerMapping, "stageCurrentState", null);
                return !_.isNil(value);
            },
            canAcceptStageCurrentZip() {
                let value = _.get(this.buyerSellerMapping, "stageCurrentZip", null);
                return !_.isNil(value);
            },
            canAcceptStageEmailAddress1() {
                let value = _.get(this.buyerSellerMapping, "stageEmailAddress1", null);
                return !_.isNil(value);
            },
            canAcceptStageEmailAddress2() {
                let value = _.get(this.buyerSellerMapping, "stageEmailAddress2", null);
                return !_.isNil(value);
            },
            canAcceptStageExtension1() {
                let value = _.get(this.buyerSellerMapping, "stageExtension1", null);
                return !_.isNil(value);
            },
            canAcceptStageExtension2() {
                let value = _.get(this.buyerSellerMapping, "stageExtension2", null);
                return !_.isNil(value);
            },
            canAcceptStageFax1() {
                let value = _.get(this.buyerSellerMapping, "stageFax1", null);
                return !_.isNil(value);
            },
            canAcceptStageFax2() {
                let value = _.get(this.buyerSellerMapping, "stageFax2", null);
                return !_.isNil(value);
            },
            canAcceptStageFirstName1() {
                let value = _.get(this.buyerSellerMapping, "stageFirstName1", null);
                return !_.isNil(value);
            },
            canAcceptStageFirstName2() {
                let value = _.get(this.buyerSellerMapping, "stageFirstName2", null);
                return !_.isNil(value);
            },
            canAcceptStageGender1() {
                let value = _.get(this.buyerSellerMapping, "stageGender1", null);
                return !_.isNil(value);
            },
            canAcceptStageGender2() {
                let value = _.get(this.buyerSellerMapping, "stageGender2", null);
                return !_.isNil(value);
            },
            canAcceptStageHomePhone() {
                let value = _.get(this.buyerSellerMapping, "stageHomePhone", null);
                return !_.isNil(value);
            },
            canAcceptStageHomePhone2() {
                let value = _.get(this.buyerSellerMapping, "stageHomePhone2", null);
                return !_.isNil(value);
            },
            canAcceptStageLastName1() {
                let value = _.get(this.buyerSellerMapping, "stageLastName1", null);
                return !_.isNil(value);
            },
            canAcceptStageLastName2() {
                let value = _.get(this.buyerSellerMapping, "stageLastName2", null);
                return !_.isNil(value);
            },
            canAcceptStageMiddleInitial1() {
                let value = _.get(this.buyerSellerMapping, "stageMiddleInitial1", null);
                return !_.isNil(value);
            },
            canAcceptStageMiddleInitial2() {
                let value = _.get(this.buyerSellerMapping, "stageMiddleInitial2", null);
                return !_.isNil(value);
            },
            canAcceptStageSocialSecurityNumber1() {
                let value = _.get(this.buyerSellerMapping, "stageSocialSecurityNumber1", null);
                return !_.isNil(value);
            },
            canAcceptStageSocialSecurityNumber2() {
                let value = _.get(this.buyerSellerMapping, "stageSocialSecurityNumber2", null);
                return !_.isNil(value);
            },
            canAcceptStageWorkPhone2() {
                let value = _.get(this.buyerSellerMapping, "stageWorkPhone2", null);
                return !_.isNil(value);
            }
        },
        watch: {
            index: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    let origMappingData = this.originalMappingData; // local var set to trigger computed in next line
                    this.originalBuyerSellerData = origMappingData[newValue];
                },
                immediate: true
            },
            stepNumber: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.index = 0; // if next step then reset index = 0
                    let origMappingData = this.originalMappingData; // local var set to trigger computed in next line
                    this.originalBuyerSellerData = origMappingData[this.index];
                },
                immediate: true
            },
        },

        methods:{
            onChangeNewBuyerSeller() {
                if (this.buyerSellerMapping.isNewBuyerSeller) {
                    // this.clearBuyerSellerData();
                    this.selectedAll = true;
                    this.setOverrides(this.selectedAll);
                }
                else {
                    this.resetOriginalData();
                }
            },
            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },
            setOverrides(value) {
                if (this.canAcceptStageBusinessName)
                    this.buyerSellerMapping.acceptStageBusinessName = value;
                if (this.canAcceptStageCellPhone1)
                    this.buyerSellerMapping.acceptStageCellPhone1 = value;
                if (this.canAcceptStageCellPhone2)
                    this.buyerSellerMapping.acceptStageCellPhone2 = value;
                if (this.canAcceptStageCurrentAddress1)
                    this.buyerSellerMapping.acceptStageCurrentAddress1 = value;
                if (this.canAcceptStageCurrentCity)
                    this.buyerSellerMapping.acceptStageCurrentCity = value;
                if (this.canAcceptStageCurrentState)
                    this.buyerSellerMapping.acceptStageCurrentState = value;
                if (this.canAcceptStageCurrentZip)
                    this.buyerSellerMapping.acceptStageCurrentZip = value;
                if (this.canAcceptStageEmailAddress1)
                    this.buyerSellerMapping.acceptStageEmailAddress1 = value;
                if (this.canAcceptStageEmailAddress2)
                    this.buyerSellerMapping.acceptStageEmailAddress2 = value;
                if (this.canAcceptStageExtension1)
                    this.buyerSellerMapping.acceptStageExtension1 = value;
                if (this.canAcceptStageExtension2)
                    this.buyerSellerMapping.acceptStageExtension2 = value;
                if (this.canAcceptStageFax1)
                    this.buyerSellerMapping.acceptStageFax1 = value;
                if (this.canAcceptStageFax2)
                    this.buyerSellerMapping.acceptStageFax2 = value;
                if (this.canAcceptStageFirstName1)
                    this.buyerSellerMapping.acceptStageFirstName1 = value;
                if (this.canAcceptStageFirstName2)
                    this.buyerSellerMapping.acceptStageFirstName2 = value;
                if (this.canAcceptStageGender1)
                    this.buyerSellerMapping.acceptStageGender1	= value;
                if (this.canAcceptStageGender2)
                    this.buyerSellerMapping.acceptStageGender2	= value;
                if (this.canAcceptStageHomePhone)
                    this.buyerSellerMapping.acceptStageHomePhone = value;
                if (this.canAcceptStageHomePhone2)
                    this.buyerSellerMapping.acceptStageHomePhone2 = value;
                if (this.canAcceptStageLastName1)
                    this.buyerSellerMapping.acceptStageLastName1 = value;
                if (this.canAcceptStageLastName2)
                    this.buyerSellerMapping.acceptStageLastName2 = value;
                if (this.canAcceptStageMiddleInitial1)
                    this.buyerSellerMapping.acceptStageMiddleInitial1 = value;
                if (this.canAcceptStageMiddleInitial2)
                    this.buyerSellerMapping.acceptStageMiddleInitial2 = value;
                if (this.canAcceptStageSocialSecurityNumber1)
                    this.buyerSellerMapping.acceptStageSocialSecurityNumber1 = value;
                if (this.canAcceptStageSocialSecurityNumber2)
                    this.buyerSellerMapping.acceptStageSocialSecurityNumber2 = value;
                if (this.canAcceptStageWorkPhone2)
                    this.buyerSellerMapping.acceptStageWorkPhone2 = value;

            },
            clearBuyerSellerData() {
                this.buyerSellerMapping.businessName = null;
                this.buyerSellerMapping.cellPhone1 = null;
                this.buyerSellerMapping.cellPhone2 = null;
                this.buyerSellerMapping.currentAddress1 = null;
                this.buyerSellerMapping.currentCity = null;
                this.buyerSellerMapping.currentState = null;
                this.buyerSellerMapping.currentZip = null;
                this.buyerSellerMapping.emailAddress1 = null;
                this.buyerSellerMapping.emailAddress2 = null;
                this.buyerSellerMapping.extension1 = null;
                this.buyerSellerMapping.extension2 = null;
                this.buyerSellerMapping.fax1 = null;
                this.buyerSellerMapping.fax2 = null;
                this.buyerSellerMapping.firstName1 = null;
                this.buyerSellerMapping.firstName2 = null;
                this.buyerSellerMapping.gender1	= null;
                this.buyerSellerMapping.gender2	= null;
                this.buyerSellerMapping.homePhone = null;
                this.buyerSellerMapping.homePhone2 = null;
                this.buyerSellerMapping.lastName1 = null;
                this.buyerSellerMapping.lastName2 = null;
                this.buyerSellerMapping.middleInitial1 = null;
                this.buyerSellerMapping.middleInitial2 = null;
                this.buyerSellerMapping.socialSecurityNumber1 = null;
                this.buyerSellerMapping.socialSecurityNumber2 = null;
                this.buyerSellerMapping.workPhone2 = null;

            },
            resetOriginalData() {
                this.buyerSellerMapping.businessName = this.originalBuyerSellerData.businessName;
                this.buyerSellerMapping.cellPhone1 = this.originalBuyerSellerData.cellPhone1;
                this.buyerSellerMapping.cellPhone2 = this.originalBuyerSellerData.cellPhone2;
                this.buyerSellerMapping.currentAddress1 = this.originalBuyerSellerData.currentAddress1;
                this.buyerSellerMapping.currentCity = this.originalBuyerSellerData.currentCity;
                this.buyerSellerMapping.currentState = this.originalBuyerSellerData.currentState;
                this.buyerSellerMapping.currentZip = this.originalBuyerSellerData.currentZip;
                this.buyerSellerMapping.emailAddress1 = this.originalBuyerSellerData.emailAddress1;
                this.buyerSellerMapping.emailAddress2 = this.originalBuyerSellerData.emailAddress2;
                this.buyerSellerMapping.extension1 = this.originalBuyerSellerData.extension1;
                this.buyerSellerMapping.extension2 = this.originalBuyerSellerData.extension2;
                this.buyerSellerMapping.fax1 = this.originalBuyerSellerData.fax1;
                this.buyerSellerMapping.fax2 = this.originalBuyerSellerData.fax2;
                this.buyerSellerMapping.firstName1 = this.originalBuyerSellerData.firstName1;
                this.buyerSellerMapping.firstName2 = this.originalBuyerSellerData.firstName2;
                this.buyerSellerMapping.gender1	= this.originalBuyerSellerData.gender1;
                this.buyerSellerMapping.gender2	= this.originalBuyerSellerData.gender2;
                this.buyerSellerMapping.homePhone = this.originalBuyerSellerData.homePhone;
                this.buyerSellerMapping.homePhone2 = this.originalBuyerSellerData.homePhone2;
                this.buyerSellerMapping.lastName1 = this.originalBuyerSellerData.lastName1;
                this.buyerSellerMapping.lastName2 = this.originalBuyerSellerData.lastName2;
                this.buyerSellerMapping.middleInitial1 = this.originalBuyerSellerData.middleInitial1;
                this.buyerSellerMapping.middleInitial2 = this.originalBuyerSellerData.middleInitial2;
                this.buyerSellerMapping.socialSecurityNumber1 = this.originalBuyerSellerData.socialSecurityNumber1;
                this.buyerSellerMapping.socialSecurityNumber2 = this.originalBuyerSellerData.socialSecurityNumber2;
                this.buyerSellerMapping.workPhone2 = this.originalBuyerSellerData.workPhone2;

                this.setOverrides(false);
            },
            onMoveNext(){     
                // return value - determines if you can progress to the next big step
                // we also need to continue to increment local index step
                if (this.index < this.maxIndex) {
                    this.index++;
                    return false; // for parent - do not progress on to next big step
                }
                
                return true; // for parent - continue to progress to next big step
            },
            onScrollUpdated(e) {
                this.updateSpacerHeight();
            },
        }
    }
</script>
