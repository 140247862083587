<template>
    <div class="content-wrapper alert-wizard">
        <rq-wizard-layout
            ref="wizardLayout"
            :steps="steps"
            finish-button-label="Next"
            v-model="currentIndex"
            @previous="onPrev"
            @next="onNext"
            @finish="onFinish"
            @direct="onDirect"
            auto-complete-steps>
            <template #header>
                <div class="nav navbar-nav ms-auto">
                    <div class="nav-item">
                        <rq-icon-button
                            icon="fas fa-times"
                            @click="$emit('close-dialog')"
                        />
                    </div>
                </div>
            </template>
            <template v-slot="{ stepInfo }">
                <component
                    ref="currentStep"
                    :is="stepInfo.component"
                    :mapping="mapping"
                    :step-number="currentIndex+1"
                    :title="stepInfo.label"
                />
            </template>
            <template #complete>
                <finish-mapping
                    :mapping="mapping"
                    @open-file="onOpenFile"
                    @close-wizard="onCloseWizard"
                />
            </template>
        </rq-wizard-layout>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import FileMapping from "./FileMapping";
    import TitleProductionMapping from "./TitleProductionMapping"
    import LoanMapping from "./LoanMapping";
    import PropertyMapping from "./PropertyMapping";
    import BuyerSellerMapping from "./BuyerSellerMapping";
    import FinishMapping from "./FinishMapping";
    import CompanyMapping from "./CompanyMapping";

    const PlaceholderComponent = { template: "<div class='rq-container'><div class='rq-title rq-title-lg p-0'>Placholder Component</div></div>" };

    export default {
        name: "AlertDataMappingWizard",
        props: {
            mapping: { type: Object, default: () => ({}) },
        },
        components: {
            FileMapping,
            TitleProductionMapping,
            LoanMapping,
            PropertyMapping,
            BuyerSellerMapping,
            FinishMapping,
            PlaceholderComponent,
            CompanyMapping
        },
        data() {
            return {
                currentIndex: 0,
                steps: []
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
        },

        created() {
            this.mapping.processOrder = !_.isEmpty(this.mapping.orderMapping);
            this.mapping.processLoans = !_.isEmpty(this.mapping.loanMappings);
            this.mapping.processProperties = !_.isEmpty(this.mapping.propertyMappings);
            this.mapping.processBuyers = !_.isEmpty(this.mapping.buyerMappings);
            this.mapping.processSellers = !_.isEmpty(this.mapping.sellerMappings);

            this.steps = [
                { title: "File Data", component: "FileMapping", visible: this.mapping.processOrder,  },
                { title: "Loan", component: "LoanMapping", visible: this.mapping.processLoans },
                { title: "Property", component: "PropertyMapping", visible: this.mapping.processProperties },
                { title: "Buyer", component: "BuyerSellerMapping", visible: this.mapping.processBuyers },
                { title: "Seller", component: "BuyerSellerMapping", visible: this.mapping.processSellers },
                { title: "Title Production", component: "TitleProductionMapping", visible: this.mapping.processCommitmentPolicy },
                { title: "Companies & Contacts", component: "CompanyMapping", visible: this.mapping.processCompanies }
            ];
        },

        methods: {
            onPrev(e){
                /* if we end up needing to hold them on a step for validation
                if([step validation failed]) {
                    e.setError([error message]);
                    return;
                }
                */
                this.currentIndex--;
            },
            onNext(e){
                /* if we end up needing to hold them on a step for validation
                if([step validation failed]) {
                    e.setError([error message]);
                    return;
                }
                */
                let currentStep =_.get(this, "$refs.currentStep", null);
                if (_.isFunction(currentStep.onMoveNext) && !_.invoke(currentStep, "onMoveNext"))
                    return;

                this.currentIndex++;
            },
            onDirect(e) {
                this.currentIndex = e.index;
            },
            onFinish(e) {
                let currentStep =_.get(this, "$refs.currentStep", null);
                if (_.isFunction(currentStep.onMoveNext) && !_.invoke(currentStep, "onMoveNext"))
                    return;

                this.finalizeMapping()
                    .then(() => {
                        e.setComplete();
                    });
            },
            finalizeMapping(){
                const self = this;
                let promise = self.$api.InternetAlertsApi.applyAlertDataMapping(self.mapping);
                return self.$rqBusy.wait(promise)
                    .catch(error => {
                        console.log(error);
                        self.$toast.error("An issue occurred during mapping finalization.");
                    });
            },
            onOpenFile(e){
                if (!e.orderMapping) return;
                this.$rq.goToFile(e.orderMapping.ordersID);
                this.$emit("close-dialog");
            },
            onCloseWizard(){
                this.$emit("ok");
            }
        }
    }
</script>

<style lang="scss">
    .alert-wizard {
        table.table:not(.rq-table) > tbody > tr {
            &:not(:last-child) {
                border-bottom: 1px solid #D2D5D7;
            }
            th {
                padding-right: 1rem;
                text-align: right;
                background-color: #E1E1EA;
            }
            td {
                padding-left: 0.5rem;
                background-color: #F5F5F5;
            }
        }
    }
</style>