<template>
    <div class="rq-container">
        <div class=row>
            <div class="col-3">
                <div class="step-title">Step {{stepNumber}}</div>
                <div class="rq-title rq-title-lg p-0">Companies &amp; Contacts</div>
            </div>
            <div class="col-9">
                <table class="table table-sm table-borderless table-secondary">
                    <tbody>
                        <tr>
                            <th>Role</th>
                            <td>{{currentRoleTypeName}}</td>
                            <th>Email</th>
                            <td>{{currentMapping.emailAddress}}</td>
                        </tr>
                        <tr>
                            <th>Company</th>
                            <td>{{currentMapping.name}}</td>
                            <th>Phone</th>
                            <td>{{currentMapping.phone}}</td>
                        </tr>
                        <tr>
                            <th>Contact</th>
                            <td>{{currentMapping.contactName}}</td>
                            <th>Fax</th>
                            <td>{{currentMapping.fax}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="step-text">Company {{currentIndex+1}} of {{maxIndex}} - {{companyDescriptor}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-2">Create New</div>
            <div class="col">
                <rq-switch
                    automation_id="chk_create_new"
                    type="text"
                    variant="primary"
                    on="Yes"
                    off="No"
                    size="sm"
                    ref="newLoanSetting"
                    v-model="currentMapping.createNew"
                    :disabled="!canCreateNew"
                     v-rq-tooltip.hover.top="{ title: createNewHoverMessage }"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-2">Map Info</div>
            <div class="col-2">
                <rq-switch
                    automation_id="chk_map_info"
                    type="text"
                    variant="primary"
                    on="Yes"
                    off="No"
                    size="sm"
                    ref="newLoanSetting"
                    v-model="currentMapping.mapToExisting"
                    :disabled="!allowEditCompanyDetails"
                />
            </div>
            <div class="col">
                <company-picker
                    ref="companyPicker"
                    automation_id="pic_mapped_company"
                    :companyRoleName="currentRoleTypeName"
                    :companyRoleId="currentMapping.roleTypeID"
                    :dialogTitle="companyPickerTitle"
                    :disabled="!currentMapping.mapToExisting"
                    v-model="currentMapping.companyLookup"
                    show-selection-summary
                    small
                    @change="onCompanyMapChanged"
                />
            </div>
        </div>
        <table class="table rq-table">
            <thead>
                <tr>
                    <td>Field Name</td>
                    <td>Current Data</td>
                    <td>Pending Data</td>
                    <td>Override?</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{currentMapping.finalName}}</td>
                    <td>{{currentMapping.stageName}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_name"
                            v-model="currentMapping.acceptStageName"
                            :disabled="!canAcceptStageName"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{{currentMapping.finalAddress}}</td>
                    <td>{{currentMapping.stageAddress}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_address"
                            v-model="currentMapping.acceptStageAddress"
                            :disabled="!canAcceptStageAddress"
                        />
                    </td>
                </tr>
                <tr>
                    <td>City</td>
                    <td>{{currentMapping.finalCity}}</td>
                    <td>{{currentMapping.stageCity}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_city"
                            v-model="currentMapping.acceptStageCity"
                            :disabled="!canAcceptStageCity"
                        />
                    </td>
                </tr>
                <tr>
                    <td>State</td>
                    <td>{{currentMapping.finalState}}</td>
                    <td>{{currentMapping.stageState}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_state"
                            v-model="currentMapping.acceptStageState"
                            :disabled="!canAcceptStageState"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Zip</td>
                    <td>{{currentMapping.finalZip}}</td>
                    <td>{{currentMapping.stageZip}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_zip"
                            v-model="currentMapping.acceptStageZip"
                            :disabled="!canAcceptStageZip"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td>{{currentMapping.finalPhone}}</td>
                    <td>{{currentMapping.stagePhone}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_phone"
                            v-model="currentMapping.acceptStagePhone"
                            :disabled="!canAcceptStagePhone"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Fax</td>
                    <td>{{currentMapping.finalFax}}</td>
                    <td>{{currentMapping.stageFax}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_fax"
                            v-model="currentMapping.acceptStageFax"
                            :disabled="!canAcceptStageFax"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{{currentMapping.finalEmail}}</td>
                    <td>{{currentMapping.stageEmail}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_email"
                            v-model="currentMapping.acceptStageEmail"
                            :disabled="!canAcceptStageEmail"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Contact</td>
                    <td>{{currentMapping.finalContactName}}</td>
                    <td>{{currentMapping.contactName}}</td>
                    <td>
                        <b-form-checkbox
                            automation_id="chk_override_contact_name"
                            v-model="currentMapping.acceptContactName"
                            :disabled="!canAcceptContactName"
                        />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">
                        <div class="text-right">
                            <b-btn
                                automation_id="btn_select_all"
                                variant="action-link"
                                @click="onSelectAll">{{selectAllLabel}}
                            </b-btn>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import CompanyPicker from "@/shared/components/rq/CompanyPicker";
    import { CompanyDataMapper } from "../../models";

    export default {
        name: "CompanyMapping",
        props: {
            stepNumber: { type: Number, default: 1 },
            mapping: { type: Object, default: () => ({}) },
        },
        components: {
            CompanyPicker
        },
        data() {
            return {
                currentIndex: 0,
                currentMapping: {},
                originalMapping: {}
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            companyMapping(){
                return this.mapping.companyMapping || [];
            },
            maxIndex() { return this.companyMapping.length; },
            currentRoleTypeName(){ return _.get(this.currentMapping, "roleTypeName", "")},
            companyPickerTitle() { return `Select ${this.currentRoleTypeName}`},
            companyDescriptor(){
                if(this.currentMapping.relatedTo){
                    return `${this.currentMapping.stageName} as ${this.currentRoleTypeName} related to ${this.currentMapping.relatedTo}`;
                }
                return `${this.currentMapping.stageName} as ${this.currentRoleTypeName}`;
            },
            canAcceptStageName(){
                let value = _.get(this.currentMapping, "stageName", null);
                if(value) return true;
                return false;
            },
            canAcceptStageAddress(){
                let value = _.get(this.currentMapping, "stageAddress", null);
                if(value) return true;
                return false;
            },
            canAcceptStageCity(){
                let value = _.get(this.currentMapping, "stageCity", null);
                if(value) return true;
                return false;
            },
            canAcceptStageState(){
                let value = _.get(this.currentMapping, "stageState", null);
                if(value) return true;
                return false;
            },
            canAcceptStageZip(){
                let value = _.get(this.currentMapping, "stageZip", null);
                if(value) return true;
                return false;
            },
            canAcceptStagePhone(){
                let value = _.get(this.currentMapping, "stagePhone", null);
                if(value) return true;
                return false;
            },
            canAcceptStageFax(){
                let value = _.get(this.currentMapping, "stageFax", null);
                if(value) return true;
                return false;
            },
            canAcceptStageEmail(){
                let value = _.get(this.currentMapping, "stageEmail", null);
                if(value) return true;
                return false;
            },
            canAcceptContactName(){
                let value = _.get(this.currentMapping, "contactName", null);
                if(value) return true;
                return false;
            },
            localSecurity(){
                return this.securitySettings.findValues(["AllowEditCompanyDetails"]);
            },
            allowEditCompanyDetails() {
                return this.localSecurity.AllowEditCompanyDetails;
            },
            currentMappingHasOverrides(){
                return this.currentMapping.acceptStageName
                    || this.currentMapping.acceptStageAddress
                    || this.currentMapping.acceptStageCity
                    || this.currentMapping.acceptStageState
                    || this.currentMapping.acceptStageZip
                    || this.currentMapping.acceptStagePhone
                    || this.currentMapping.acceptStageFax
                    || this.currentMapping.acceptStageEmail
                    || this.currentMapping.acceptContactName;
            },
            canCreateNew(){
                return this.allowEditCompanyDetails;
            },
            createNewHoverMessage(){
                return this.allowEditCompanyDetails ? "" : "Insufficient privileges to create new companies in the system";
            },
            areAllSelected(){
                return (this.canAcceptStageName && this.currentMapping.acceptStageName || this.canAcceptStageName === false)
                    && (this.canAcceptStageAddress && this.currentMapping.acceptStageAddress || this.canAcceptStageAddress === false)
                    && (this.canAcceptStageCity && this.currentMapping.acceptStageCity || this.canAcceptStageCity === false)
                    && (this.canAcceptStageState &&  this.currentMapping.acceptStageState || this.canAcceptStageState === false)
                    && (this.canAcceptStageZip && this.currentMapping.acceptStageZip || this.canAcceptStageZip === false)
                    && (this.canAcceptStagePhone && this.currentMapping.acceptStagePhone || this.canAcceptStagePhone === false)
                    && (this.canAcceptStageFax && this.currentMapping.acceptStageFax || this.canAcceptStageFax === false)
                    && (this.canAcceptStageEmail && this.currentMapping.acceptStageEmail || this.canAcceptStageEmail === false)
                    && (this.canAcceptContactName && this.currentMapping.acceptContactName || this.canAcceptContactName === false);
            },
            selectAllLabel(){
                return this.areAllSelected ? "Unselect All" : "Select All";
            }
        },
        watch: {
            currentIndex: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.currentMapping = new CompanyDataMapper(this.companyMapping[newValue]);
                    this.originalMapping = _.cloneDeep(this.currentMapping);
                },
                immediate: true
            },
            "currentMapping.createNew": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || !this.canCreateNew) return;
                    if(newValue)
                        this.currentMapping.mapToExisting = !newValue;
                    this.onChangeCreateNew();
                },
                immediate: true
            },
            "currentMapping.mapToExisting": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(newValue && !this.allowEditCompanyDetails){
                        this.currentMapping.mapToExisting = false;
                        return;
                    }
                    if(newValue)
                        this.currentMapping.createNew = !newValue;
                },
                immediate: true
            },
            "currentMapping.companyID": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(newValue && newValue > 0){
                        this.refreshCompany();
                    }

                },
                immediate: true
            },
        },
        created() {
            this.currentMapping = this.companyMapping.length > 0 ? this.companyMapping[0] : {}
        },

        methods:{
            onSelectAll(){
                this.setOverrides(!this.areAllSelected);
            },

            onMoveNext(){
                this.currentIndex++;
                if (this.currentIndex < this.maxIndex) {
                    return false;
                }
                return true;
            },

            onChangeCreateNew(){
                this.setOverrides(this.currentMapping.createNew);
            },

            setOverrides(value){
                if(this.canAcceptStageName)
                    this.currentMapping.acceptStageName = value;
                if(this.canAcceptStageAddress)
                    this.currentMapping.acceptStageAddress = value;
                if(this.canAcceptStageCity)
                    this.currentMapping.acceptStageCity = value;
                if(this.canAcceptStageState)
                    this.currentMapping.acceptStageState = value;
                if(this.canAcceptStageZip)
                    this.currentMapping.acceptStageZip = value;
                if(this.canAcceptStagePhone)
                    this.currentMapping.acceptStagePhone = value;
                if(this.canAcceptStageFax)
                    this.currentMapping.acceptStageFax = value;
                if(this.canAcceptStageEmail)
                    this.currentMapping.acceptStageEmail = value;
            },

            refreshCompany(){
                if(_.isNil(this.currentMapping)) return;
                if(!this.currentMapping.mapToExisting) return;
                const self = this;
                let promise = self.$api.CompaniesApi.getCompany(self.currentMapping.companyID);
                self.$rqBusy.wait(promise).then((result)=>{
                    self.currentMapping.name = result.name;
                    self.currentMapping.address = result.address1;
                    self.currentMapping.city = result.city;
                    self.currentMapping.state = result.state;
                    self.currentMapping.zip = result.zip;
                    self.currentMapping.phone = result.phone;
                    self.currentMapping.fax = result.fax;
                    self.currentMapping.email = result.email;
                });
            },

            onCompanyMapChanged(){
                const self = this;
                if(self.currentMapping.name === self.currentMapping.stageName) return;
                if(self.currentMapping.companyID === null) return;
                let ok = function(){
                    return true;
                }

                let cancel = function(){
                    self.revertMap();
                    self.currentMapping.mapToExisting = false;
                    return true;
                }
                self.$dialog.confirm("Confirm Mapping", `Company Name is ${self.currentMapping.name} and Pending Company Name is ${self.currentMapping.stageName} are you sure you want to Map Info?`, ok, cancel, { cancelTitle: 'No', okTitle: 'Yes'})
            },

            revertMap(){
                const self = this;
                self.currentMapping.companyID = self.originalMapping.companyID;
                self.currentMapping.name = self.originalMapping.name;
                self.currentMapping.address = self.originalMapping.address1;
                self.currentMapping.city = self.originalMapping.city;
                self.currentMapping.state = self.originalMapping.state;
                self.currentMapping.zip = self.originalMapping.zip;
                self.currentMapping.phone = self.originalMapping.phone;
                self.currentMapping.fax = self.originalMapping.fax;
                self.currentMapping.email = self.originalMapping.email;
            }
        }
    }
</script>
