<template>
    <div class="rq-container content-wrapper">
        <div class="step-title">Step {{stepNumber}}</div>
        <rq-page-section
            title="Title Production and Policy Data for Order Detail"
            header-size="lg"
            borderless>
        </rq-page-section>
        <div class="wizard-table-section">
            <div class="rq-table-container rq-table-container-bordered">
                <rq-scroll-container
                    ref="psElement"
                    ps-class-attr="rq-table-scroll-container"
                    @updated="onScrollUpdated"
                    perfect-scrollbar
                    update-on-resize>
                    <table ref="tableElement" class="rq-table">

                    <thead>
                        <tr>
                            <td>Field Name</td>
                            <td>Current Data</td>
                            <td>Pending Data</td>
                            <td>Override?</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Remit Date Lock</td>
                            <td>{{currentDetailMapping.finalRemitDateLock}}</td>
                            <td>{{currentDetailMapping.stageRemitDateLock}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_remit_date_lock"
                                    v-model="currentDetailMapping.acceptStageRemitDateLock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Binder Strike 1A</td>
                            <td>{{currentDetailMapping.finalBinderStrike1A}}</td>
                            <td>{{currentDetailMapping.stageBinderStrike1A}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_binder_strike_1a"
                                    v-model="currentDetailMapping.acceptStageBinderStrike1A"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Binder B13 Strike</td>
                            <td>{{currentDetailMapping.finalBinderB13Strike}}</td>
                            <td>{{currentDetailMapping.stageBinderB13Strike}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_binder_b13_strike"
                                    v-model="currentDetailMapping.acceptStageBinderB13Strike"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Binder B13 Tax Language</td>
                            <td>{{currentDetailMapping.finalBinderB13TaxLanguage}}</td>
                            <td>{{currentDetailMapping.stageBinderB13TaxLanguage}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_owner_b13_tax_language"
                                    v-model="currentDetailMapping.acceptStageBinderB13TaxLanguage"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Insured</td>
                            <td v-if="showMortgageInsuredDiff">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalMortgageInsured, currentDetailMapping.stageMortgageInsuredText, 'Mortgage Insured')">View
                                </button>
                            </td>
                            <td v-else>
                                    {{currentDetailMapping.finalMortgageInsured}}
                            </td>
                            <td v-if="showMortgageInsuredDiff">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalMortgageInsured, currentDetailMapping.stageMortgageInsuredText, 'Mortgage Insured')">View
                                </button>
                            </td>
                            <td v-else>
                                {{currentDetailMapping.stageMortgageInsuredText}}
                            </td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_insured"
                                    v-model="currentDetailMapping.acceptStageMortgageInsured"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Insured Override</td>
                            <td>{{currentDetailMapping.finalMortgageInsuredOverride}}</td>
                            <td>{{currentDetailMapping.stageMortgageInsuredOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_insured_override"
                                    v-model="currentDetailMapping.acceptStageMortgageInsuredOverride"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Borrower</td>
                            <td v-if="showMortgageBorrowerDiff">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalMortgageBorrower, currentDetailMapping.stageMortgageBorrowerText, 'Morgage Borrower')">View
                                </button>
                            </td>
                            <td v-else>
                                {{currentDetailMapping.finalMortgageBorrower}}
                            </td>
                            <td v-if="showMortgageBorrowerDiff">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalMortgageBorrower, currentDetailMapping.stageMortgageBorrowerText, 'Morgage Borrower')">View
                                </button>
                            </td>
                            <td v-else>
                                {{currentDetailMapping.stageMortgageBorrowerText}}
                            </td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_borrower"
                                    v-model="currentDetailMapping.acceptStageMortgageBorrower"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Borrower Override</td>
                            <td>{{currentDetailMapping.finalMortgageBorrowerOverride}}</td>
                            <td>{{currentDetailMapping.stageMortgageBorrowerOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_borrower_override"
                                    v-model="currentDetailMapping.acceptStageMortgageBorrowerOverride"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Construction Insured</td>
                            <td>{{currentDetailMapping.finalConstructionInsured}}</td>
                            <td>{{currentDetailMapping.stageConstructionInsuredText}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_construction_insured"
                                    v-model="currentDetailMapping.acceptStageConstructionInsured"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Construction Insured Override</td>
                            <td>{{currentDetailMapping.finalConstructionInsuredOverride}}</td>
                            <td>{{currentDetailMapping.stageConstructionInsuredOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_construction_insured_override"
                                    v-model="currentDetailMapping.acceptStageConstructionInsuredOverride"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Construction Borrower</td>
                            <td>{{currentDetailMapping.finalConstructionBorrower}}</td>
                            <td>{{currentDetailMapping.stageConstructionBorrowerText}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_construction_borrower"
                                    v-model="currentDetailMapping.acceptStageConstructionBorrower"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Construction Borrower Override</td>
                            <td>{{currentDetailMapping.finalConstructionBorrowerOverride}}</td>
                            <td>{{currentDetailMapping.stageConstructionBorrowerOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_construction_Borrower_override"
                                    v-model="currentDetailMapping.acceptStageConstructionBorrowerOverride"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Schedule B</td>
                            <td>{{currentDetailMapping.finalMortgageScheduleB}}</td>
                            <td>{{currentDetailMapping.stageMortgageScheduleB}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_scheduleB"
                                    v-model="currentDetailMapping.acceptStageMortgageScheduleB"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Schedule B Override</td>
                            <td>{{currentDetailMapping.finalMortgageScheduleBOverride}}</td>
                            <td>{{currentDetailMapping.stageMortgageScheduleBOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_schedule_b_override"
                                    v-model="currentDetailMapping.acceptStageMortgageScheduleBOverride"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Mortgage Schedule B2 Override</td>
                            <td>{{currentDetailMapping.finalMortgageScheduleB2Override}}</td>
                            <td>{{currentDetailMapping.stageMortgageScheduleB2Override}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_mortgage_schedule_b2_override"
                                    v-model="currentDetailMapping.acceptStageMortgageScheduleB2Override"
                                />
                            </td>
                        </tr>     
                        <tr>
                            <td>Insured Mortgage Override</td>
                            <td>{{currentDetailMapping.finalInsuredMortgageOverride}}</td>
                            <td>{{currentDetailMapping.stageInsuredMortgageOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_insured_mortgage_override"
                                    v-model="currentDetailMapping.acceptStageInsuredMortgageOverride"
                                />
                            </td>
                        </tr>           
                        <tr>
                            <td>Title Vested In</td>
                            <td>{{currentDetailMapping.finalTitleVestedIn}}</td>
                            <td>{{currentDetailMapping.stageTitleVestedInText}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_title_vested_in"
                                    v-model="currentDetailMapping.acceptStageTitleVestedIn"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Title Vested In Override</td>
                            <td>{{currentDetailMapping.finalTitleVestedInOverride}}</td>
                            <td>{{currentDetailMapping.stageTitleVestedInOverride}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_title_vested_in"
                                    v-model="currentDetailMapping.acceptStageTitleVestedInOverride"
                                />
                            </td>
                        </tr>    
                        <tr>
                            <td>Legal Description</td>
                            <td>
                                <button
                                    v-if="showLegalDescContentDiff"
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalLegalDescription, currentDetailMapping.stageLegalDescriptionText, 'Legal Description')">View
                                </button>
                            </td>
                            <td>
                                <button
                                    v-if="showLegalDescContentDiff"
                                    type="button"
                                    class="btn btn-sm btn-link btn-theme btn-action-link"
                                    @click="openContentDiff(currentDetailMapping.finalLegalDescription, currentDetailMapping.stageLegalDescriptionText, 'Legal Description')">View
                                </button>
                            </td>
                            <td>
                                <b-form-checkbox automation_id="chk_override_legal_description"
                                    v-model="currentDetailMapping.acceptStageLegalDescription" />
                            </td>
                        </tr>
                        <tr>
                            <td>Strike Mortgage B2</td>
                            <td>{{currentDetailMapping.finalStrikeMortgageB2}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgageB2}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgage_b2"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgageB2"
                                />
                            </td>
                        </tr>                                                                                                            
                        <tr>
                            <td>Strike EOMB3</td>
                            <td>{{currentDetailMapping.finalStrikeEOMB3}}</td>
                            <td>{{currentDetailMapping.stageStrikeEOMB3}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_emob3"
                                    v-model="currentDetailMapping.acceptStageStrikeEOMB3"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Add Standy B3</td>
                            <td>{{currentDetailMapping.finalAddStandyB3}}</td>
                            <td>{{currentDetailMapping.stageAddStandyB3}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_add_standy_b3"
                                    v-model="currentDetailMapping.acceptStageAddStandyB3"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Strike Mortgage B4</td>
                            <td>{{currentDetailMapping.finalStrikeMortgageB4}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgageB4}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgage_b4"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgageB4"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>No Second Lien</td>
                            <td>{{currentDetailMapping.finalNoSecondLien}}</td>
                            <td>{{currentDetailMapping.stageNoSecondLien}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_no_second_lien"
                                    v-model="currentDetailMapping.acceptStageNoSecondLien"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Strike Mortgage B42</td>
                            <td>{{currentDetailMapping.finalStrikeMortgageB42}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgageB42}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgage_b42"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgageB42"
                                />
                            </td>
                        </tr>                                                                                                                    
                        <tr>
                            <td>Strike Mortgagee 1</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee1}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee1}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee1"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee1"
                                />
                            </td>
                        </tr>     
                        <tr>
                            <td>Strike Mortgagee 2</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee2}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee2}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee2"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee2"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Strike Mortgagee 3</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee3}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee3}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee3"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee3"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 4</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee4}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee4}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee4"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee4"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 5</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee5}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee5}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee5"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee5"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 6</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee6}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee6}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee6"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee6"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 7</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee7}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee7}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee7"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee7"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 8</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee8}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee8}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee8"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee8"
                                />
                            </td>
                        </tr>            
                        <tr>
                            <td>Strike Mortgagee 9</td>
                            <td>{{currentDetailMapping.finalStrikeMortgagee9}}</td>
                            <td>{{currentDetailMapping.stageStrikeMortgagee9}}</td>
                            <td>
                                <b-form-checkbox
                                    automation_id="chk_override_strike_mortgagee9"
                                    v-model="currentDetailMapping.acceptStageStrikeMortgagee9"
                                />
                            </td>
                        </tr>
                        <RqTableSpacerRow
                            :height="spacerHeight"
                            :col-count="colCount"
                        />                                                                                                                                                                                                               
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                <div class="text-right">
                                    <b-btn
                                        automation_id="btn_select_all"
                                        variant="action-link"
                                        @click="onToggleSelectAll">{{selectAllLabel}}
                                    </b-btn>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                </rq-scroll-container>
            </div>
        </div>                
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { RqTableSpacerRow, useRqTableSpacer } from "@/shared/components/rq/RqTableSpacerRow";
    import {useGlobalDialogs} from "@/shared/composables"

    export default {
        name: "CommitmentPolicyDetailMapping",
        props: {
            stepNumber: { type: Number, default: 6 },
            mapping: [],
        },
        components: {
            RqTableSpacerRow
        },
        setup() {
            const{
                tableElement,
                spacerHeight,
                updateSpacerHeight
            } = useRqTableSpacer();

            const {
                openContentDiff
            } = useGlobalDialogs();

            return {
                tableElement,
                spacerHeight,
                updateSpacerHeight,
                openContentDiff
            };

        },
        data() {
            return { 
                selectedAll: false,
                detailIndex: 0,
                originalMappingData: _.cloneDeep(this.mapping),
                originalDetailMapping: {},
                colCount: 4
                // currentDetailMapping: {}
            }
        },

        created() {
            //this.currentDetailMapping = this.detailMappings[0];
        },

        watch: {
            detailIndex: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.originalDetailMapping = this.originalMappingData[newValue];
                },
                immediate: true
            },
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            detailMappings(){
                return this.mapping || null;
            },
            currentDetailMapping() {
                return this.detailIndex < this.maxIndex ? this.detailMappings[this.detailIndex] : this.detailMappings[this.detailIndex - 1];
            },
            selectAllLabel() { return this.selectedAll ? "Unselect All" : "Select All"},
            maxIndex() { return this.detailMappings.length; },
            showLegalDescContentDiff() {
                return !_.isEmpty(this.currentDetailMapping.finalLegalDescription)
                    || !_.isEmtpy(this.currentDetailMapping.stageLegalDescriptionText);
            },
            showMortgageInsuredDiff() {
                return !_.isEmpty(this.currentDetailMapping.finalMortgageInsured)
                    || !_.isEmpty(this.currentDetailMapping.stageMortgageInsuredText);
            },
            showMortgageBorrowerDiff() {
                return !_.isEmpty(this.currentDetailMapping.finalMortgageBorrower)
                    || !_.isEmpty(this.currentDetailMapping.stageMortgageBorrowerText);
            }
        },

        methods:{
            getStringLength(str) {
                return str ? str.length : 0;
            },
            onMoveNext(){
                this.detailIndex++;
                if (this.detailIndex < this.maxIndex) {
                    //this.currentDetailMapping = this.detailMappings[this.detailIndex];
                    return false;
                }
                return true;
            },

            onToggleSelectAll(){
                this.selectedAll = !this.selectedAll;
                this.setOverrides(this.selectedAll);
            },

            setOverrides(value){              
                this.currentDetailMapping.acceptStageRemitDateLock = value;
                this.currentDetailMapping.acceptStageBinderStrike1A = value;
                this.currentDetailMapping.acceptStageBinderB13Strike = value;
                this.currentDetailMapping.acceptStageBinderB13TaxLanguage = value;
                this.currentDetailMapping.acceptStageMortgageInsured = value;
                this.currentDetailMapping.acceptStageMortgageInsuredOverride = value;
                this.currentDetailMapping.acceptStageMortgageBorrower = value;
                this.currentDetailMapping.acceptStageMortgageBorrowerOverride = value;
                this.currentDetailMapping.acceptStageConstructionInsured = value;
                this.currentDetailMapping.acceptStageConstructionInsuredOverride = value;
                this.currentDetailMapping.acceptStageConstructionBorrower = value;
                this.currentDetailMapping.acceptStageConstructionBorrowerOverride = value;
                this.currentDetailMapping.acceptStageMortgageScheduleB = value;
                this.currentDetailMapping.acceptStageMortgageScheduleBOverride = value;
                this.currentDetailMapping.acceptStageMortgageScheduleB2Override = value;
                this.currentDetailMapping.acceptStageInsuredMortgageOverride = value;
                this.currentDetailMapping.acceptStageTitleVestedIn = value;
                this.currentDetailMapping.acceptStageTitleVestedInOverride = value;
                this.currentDetailMapping.acceptStageLegalDescription = value;
                this.currentDetailMapping.acceptStageStrikeMortgageB2 = value;
                this.currentDetailMapping.acceptStageStrikeEOMB3 = value;
                this.currentDetailMapping.acceptStageAddStandyB3 = value;
                this.currentDetailMapping.acceptStageStrikeMortgageB4 = value;
                this.currentDetailMapping.acceptStageNoSecondLien = value;
                this.currentDetailMapping.acceptStageStrikeMortgageB42 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee1 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee2 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee3 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee4 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee5 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee6 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee7 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee8 = value;
                this.currentDetailMapping.acceptStageStrikeMortgagee9 = value;
            },
            onScrollUpdated(e) {
                this.updateSpacerHeight();
            },
        }
    }
</script>
